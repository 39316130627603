<template>
    <div full-height grey-back>
        <v-list v-if="$root.tripOnLoadData.AttendeeInfo">
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>{{$root.tripOnLoadData.AttendeeInfo.AttendeeName}}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-avatar color="#f0f0f0">
                    <v-img :src="profilePicture" v-if="$root.tripOnLoadData.AttendeeInfo.ProfilePicture"></v-img>
                    <v-icon v-else>person</v-icon>
                </v-list-item-avatar>   
            </v-list-item>
        </v-list>

        <v-list two-line class="pa-0 mt-2">
            <v-list-item @click="fn_changeTrip">
                <v-list-item-icon>
                    <v-icon>swap_horiz</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Change Trip</v-list-item-title>
                    <v-list-item-subtitle>Switch to another trip</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <!-- <v-divider></v-divider> -->

            <!-- <v-list-item to="/platinumclub/qrcode">
                <v-list-item-icon>
                    <v-icon>qr_code_scanner</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Generate QR Code</v-list-item-title>
                    <v-list-item-subtitle>Generate QR Code to share information</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item> -->

            <!-- <v-list-item to="/platinumclub/gifts">
                <v-list-item-icon>
                    <v-icon>redeem</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>My Gifts</v-list-item-title>
                    <v-list-item-subtitle>View and collect your gifts</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider> -->

            <!-- <v-list-item @click="fn_checkCameraPermission">
                <v-list-item-icon>
                    <v-icon>qr_code_scanner</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Scan QR Code</v-list-item-title>
                    <v-list-item-subtitle>Scan QR Code to verify gifting details</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item> -->

            <!-- <v-divider></v-divider>

            <v-list-item to="/platinumclub/photoalbum">
                <v-list-item-icon>
                    <v-icon>photo_library</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Photo Album</v-list-item-title>
                    <v-list-item-subtitle>View your photo album</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item> -->

            <!-- <v-divider></v-divider>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon>help_outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Help &amp; Support</v-list-item-title>
                    <v-list-item-subtitle>FAQs &amp; More</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item> -->

            <v-divider v-if="$root.tripOnLoadData.AttendeeInfo && $root.tripOnLoadData.AttendeeInfo.AttendeeType != 2"></v-divider>

            <v-list-item v-if="$root.tripOnLoadData.AttendeeInfo && $root.tripOnLoadData.AttendeeInfo.AttendeeType != 2" @click="fn_gotoQRPage">
                <v-list-item-icon>
                    <v-icon>mdi-share-variant-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Share App</v-list-item-title>
                    <v-list-item-subtitle>Share with Hosted Guest
                        
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider v-if="$root.tripOnLoadData.AttendeeInfo && $root.tripOnLoadData.AttendeeInfo.IsMobileAdmin"></v-divider>

            <v-list-item v-if="$root.tripOnLoadData.AttendeeInfo && $root.tripOnLoadData.AttendeeInfo.IsMobileAdmin" @click="fn_openAdminApp">
                <v-list-item-icon>
                    <v-icon>mdi-account-cog</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Admin Process</v-list-item-title>
                    <v-list-item-subtitle>Admin Process</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item @click="fn_about">
                <v-list-item-icon>
                    <v-icon>info_outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>About</v-list-item-title>
                    <v-list-item-subtitle>More about app</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            
            <v-divider></v-divider>

            <v-list-item class="logout-item" @click="$root.fn_logOut">
                <v-list-item-icon class="mb-4">
                    <v-icon color="red">logout</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- <v-divider></v-divider> -->

            <!-- <v-list-item @click="$root.fn_testFn">
                <v-list-item-icon class="mb-4">
                    <v-icon>info</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>For Testing</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider> -->

            <!-- <v-list-item class="logout-item" @click="$root.fn_logOut">
                <v-list-item-icon class="mb-4">
                    <v-icon color="red">logout</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
            </v-list-item> -->

            <!-- <v-divider></v-divider>

            <v-list-item @click="$root.fn_copyLogs">
                <v-list-item-icon class="mb-4">
                    <v-icon>content_copy</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Copy Logs</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item @click="$root.fn_token">
                <v-list-item-icon class="mb-4">
                    <v-icon>more_horiz</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>For Development</v-list-item-title>
                </v-list-item-content>
            </v-list-item> -->

            <!-- <v-btn color="success" @click="fn_downloadImage">text</v-btn> -->


        </v-list>
    </div>
</template>

<script>
import axios from 'axios';
  export default {
    data: () => ({
        profilePicture:null,
    }),
    methods: {
        fn_about: function(){
          this.$router.push({path:'/about'});
        },
        
        fn_changeTrip: function(){
            this.$router.push({path:'/trips', query:{goback:1}})
        },

        fn_gotoQRPage: function(){
            //this.$router.push({name:'emearesort', params:{pageName:pagename}});
            if(this.$root.tripOnLoadData.AttendeeInfo.IsBringGuest)
                this.$router.push('/platinumclub/guestqrcode');
            else
                this.$root.fn_showToast('No guest exist to share app!');
        },

        fn_checkCameraPermission: function(){
            let self = this;
            this.getData('CheckCameraPermission', null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.fn_scanQR();
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            ); 
        },

        fn_downloadImage: function(){
            let self = this;
            let dataObj = {};
            dataObj.ImageName = "abc.png";
            dataObj.ImageUrl = "https://picsum.photos/id/11/10/6";
            this.getServerData('DownloadImage', dataObj, {
                onSuccess: function(response){
                    console.log(response);
                    self.fn_getImage();
                }, 
                onError: function(error){
                    console.log(error);
                    self.$root.fn_showToast('exception');
                }, 
            });
        },

        fn_getImage: function(){
            this.getData('GetImage', 'abc.png', 
                {
                    onSuccess: function(response){
                        console.log(response);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_shareImage: function(){
            this.getData('ShareImage', 'abc.png', 
                {
                    onSuccess: function(response){
                        console.log(response);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_scanQR: function(){
            this.getData('OpenScanner', null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_openAdminApp: function(){
            console.log('adminview');
            let postObj = {};
            let baseChatUrl = "";
            console.log(this.$root.Envirenment);
            if(this.$root.Envirenment == 3){
                baseChatUrl = "https://pcgctripadmin-dev.web.app/";
            }
            else{
                baseChatUrl = "https://pcgctripadmin-dev.web.app/";
            }

            postObj.url = baseChatUrl + "?tid="+this.$root.tripSiteId;

            this.postData('OpenAdminApp', postObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_photoAuth: function() {

            this.loading = true;
            this.error = false;
            let self = this;

            if (self.$root.tripOnLoadData.AttendeeInfo.ProfilePicture && !self.profilePicture.startsWith('data:image/')){
                this.$root.fn_getToken(token=>{
                    if(token == 'error'){
                        this.loading = false;
                        this.error = true;
                        this.$root.fn_showToast('exception');
                    }
                    else{
                        var headersObj = {
                            headers: { 
                                'Authorization': 'bearer '+token,
                            }
                        };

                        var getUrl = self.$root.baseUrl + self.$root.tripOnLoadData.AttendeeInfo.ProfilePicture;
                        axios.get(getUrl, headersObj, {timeout:30000})
                        .then(response => {
                            self.profilePicture = 'data:image/png;base64,' + response.data;
                            console.log(self.attendeeInfo.ImageUrl);
                            this.loading = false;
                            self.$forceUpdate();
                        }).catch(e => {
                            this.loading = false;
                            this.error = true;
                            let toastMsg = e && e.message ? e.message : 'exception';
                            this.$root.fn_showToast(toastMsg);
                            console.log(e);
                        })
                    }
                })

            }
        }
    },
    mounted() {
        this.fn_photoAuth();
        this.$root.fn_postClickMetrics('Profile', 'Default', null);
    },
  }
</script>