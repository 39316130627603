<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div style="padding-bottom: 30px;">
            <template v-if="loading">
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img height="168" :lazy-src="$root.imagePlaceholder" src="../assets/2024/PlatinumClub_Header_Headshots_1125x450.jpg"></v-img>

                <template>

                    <overview :content="pageOverview.ComponentText" v-if="pageOverview && !(pageOverview.ComponentText == null || pageOverview.ComponentText == '')"></overview>

                    <div class="ma-4" :class="{'mt-0' : pageOverview && !(pageOverview.ComponentText == null || pageOverview.ComponentText == '')}" v-if="headshotContent && headshotContent != null">
                        <v-card flat class="zindex2">
                            <div v-if="headshotContent.Photos && headshotContent.Photos.length==0">
                                <div class="pb-3"><strong>See your confirmed headshot schedule below</strong></div>
                                <div class="pb-3"><strong>Date: </strong> {{headshotContent.SlotTime | moment("ddd, MMM Do YYYY")}}</div>
                                <div class="pb-0"><strong>Time: </strong> {{headshotContent.SlotTime | moment("hh:mm A")}}</div>
                                <div class="text-center mb-3" v-if="HeadShotSlotStatus">
                                    <v-btn class="mt-4" outlined @click="fn_editHeadShot"><v-icon left>mdi-swap-horizontal</v-icon> Re-Schedule</v-btn>
                                </div>
                            </div>
                            <v-card-actions v-if="headshotContent.Photos && headshotContent.Photos.length>0" class="pa-0y headshot_image_container">
                                <v-layout class="tile-section_image" row wrap ma-0 style="margin-left: -8px !important;">
                                    <template v-for="(image, imageindex) in headshotContent.Photos">
                                        <v-flex xs6 sm6 md6 mt-2 pl-2 :key="imageindex" style="position: relative;">
                                            <v-img :lazy-src="$root.imagePlaceholder" :src="image.AttendeeThumbnailURL"></v-img>
                                            <v-btn @click="fn_getImage(image.PhotoName, image.AttendeePhotoDownloadURL)" icon outlined>
                                                <v-icon>mdi-download</v-icon>
                                            </v-btn>
                                        </v-flex>   
                                    </template>
                                </v-layout>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </div>
                </template>
                

            </template>
            

            <template v-if="!loading && headshotContent == null && !error && HeadShotSlotStatus">
                <div class="placeholder-wrapper pickyourslot_section content-page-placeholder pa-4">
                    <div center-align>
                        <!-- <div>
                            <v-icon class="placeholder-icon">mdi-target-account</v-icon>
                        </div> -->
                        <v-btn class="mt-4" outlined @click="fn_selectSlot"><v-icon left>mdi-image-size-select-large</v-icon> Click to Schedule</v-btn>
                    </div>
                </div>
            </template>
            
        </div>

        <div class="pcfooter_image">
            <img src="../assets/2024/PlatinumClub_SideWaveBG_1120x920.png">
        </div>
        <div class="pcfooter_wave_image">
            <img src="../assets/2024/PlatinumClub_BottomWave_1120x112.png">
        </div>

        <!-- <div class="pcfooter_image">
            <img v-show="0" src="../assets/PlatinumClub2023_FooterImage_420x200.png">
        </div> -->

        <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getDetails">Refresh Now</v-btn>
            </template>
        </v-snackbar>

        <v-dialog v-model="SlotSelectionDialog" scrollable transition="dialog-bottom-transition" fullscreen persistent>
            <v-card>
                <v-card-title :style="{ backgroundColor: $root.themeColor}" :color="$root.themeColor"  class="title pb-4 pl-4 white--text" style="flex-wrap:nowrap;word-break: break-word;">
                    <v-btn :color="$root.themeColor" icon class="mr-4 white--text" @click="SlotSelectionDialog=false">
                        <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
                    </v-btn>
                    Headshots Slot Selection
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="scrollable-content form_dialog_text pt-4 platinumclub_custom_formatting">
                    <template v-if="loading">
                        <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
                    </template>
                    <template v-else>
                        <div>
                            <div class="subtitle-1 pt-2">Select Date<span class="red--text">*</span></div>
                            <v-radio-group class="mt-2 dateselect_btn" row v-model="slotDate" hide-details>
                                <v-radio @change="fn_dateChange" class="mb-2" v-for="(item, index) in SlotList" :key="index" :label="item.SlotDate | moment('ddd, MMM Do YYYY')" :value="item.SlotDate"></v-radio>
                            </v-radio-group>
                        </div>
                        <div v-if="slotDate != null && slotDate != ''">
                            <div class="subtitle-1 pt-2">Select Time<span class="red--text">*</span></div>
                            <v-radio-group row class="mt-2 timeselect_btn" v-model="MobileHeadShotSlotID" hide-details>
                                <div class="mb-2 width_100" v-for="(slottime, index) in groupedSlots" :key="index">
                                    <v-radio :disabled="MobileHeadShotSlotID != item.MobileHeadShotSlotID && item.IsSlotFull == true" :style="{ borderColor: item.MobileHeadShotSlotID == MobileHeadShotSlotID ? $root.themeColor : ''}" class="mb-4" v-for="(item, index2) in slottime.slots" :key="index2" :label="item.SlotTime | moment('hh:mm A')" :value="item.MobileHeadShotSlotID"></v-radio>
                                </div>
                                
                            </v-radio-group>
                        </div>
                    </template>
                    
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions v-if="!loading">
                    <v-spacer></v-spacer>
                    <v-btn block :disabled="MobileHeadShotSlotID == null" :color="$root.themeColor" tile class="btn-white mt-1 pl-3 pr-3 white--text confirmation_btn" @click="fn_SaveHeadShotSelection">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="downloading" persistent width="300">
            <v-card>
                <v-card-text class="pt-4">Downloading...
                    <v-progress-linear indeterminate color="primary" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="slotFullSnackbarMsg" :timeout="1000">
            This timeslot is full, Please select other timeslot!
        </v-snackbar>

    </div>
</template>

<script>
// import accordians from './sub_components/accordians'
import overview from './sub_components/overview'
import {pageMixin} from './info_page_mixin'

import axios from 'axios';

export default{
    mixins:[pageMixin],
    components:{
        'overview':overview
    },
    data() {
        return {
            error:false,
            headshotContent:null,
            SlotSelectionDialog: false,
            SlotList: [],
            MobileHeadShotSlotID: null,
            imageName: null,
            imageUrl: null,
            downloading:false,
            pageOverview: null,
            slotDate: null,
            slotListItems: [],
            HeadShotSlotStatus: null,
            GrouppedNavList: [],
            snackbarMsg: false,
            slotFullSnackbarMsg: false
        }
    },
    methods:{
        fn_updateAvailable: function(){
            console.log('update available');
        },

        fn_dateChange: function(){
            this.MobileHeadShotSlotID = null;
        },

        fn_editHeadShot: function(){
            this.slotDate = this.headshotContent.SlotDate;
            this.MobileHeadShotSlotID = this.headshotContent.MobileHeadShotSlotID;
            this.SlotSelectionDialog = true;
            // this.$root.fn_postClickMetrics('Headshots', 'Re-Schedule', this.MobileHeadShotSlotID);
            this.fn_getSlotList();
        },

        fn_getDetails: function(){

            this.loading = true;
            this.error = false;
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loading = false;
                }
                else{
                    var headersObj = {
                        headers: { 
                            'Authorization': 'bearer '+token,
                            }
                        };

                    axios.get(this.$root.baseUrl + "/api/mobileapp/headshots/slot/booked/view", headersObj, {timeout:30000})
                    .then(response => {
                        this.loading = false;
                        console.log(response);
                        this.snackbarMsg = false;
                        let headshotPageInfo = response.data && response.data.MobilePage != null ? response.data.MobilePage : null;
                        this.headshotContent = response.data && response.data.AttendeeBookingSlot != null ? response.data.AttendeeBookingSlot : null;
                        this.HeadShotSlotStatus = response.data && response.data.HeadShotSlotStatus != null ? response.data.HeadShotSlotStatus : null;
                        if(headshotPageInfo){
                            this.pageName = headshotPageInfo.PageTitle;
                            let overviewInfo = JSON.parse(headshotPageInfo.PageComponents) 
                            this.pageOverview = overviewInfo && overviewInfo.length ? overviewInfo[0] : null;
                        }
                        this.fn_photoAuthThumbnail();
                        // if(this.headshotContent){
                        //     this.imageName = this.headshotContent.PhotoName;
                        //     this.imageUrl = this.headshotContent.AttendeePhotoURL;
                        // }
                        
                    }).catch(e => {
                        this.loading = false;
                        // this.error = true;
                        console.log(e);
                        // let toastMsg = e && e.message ? e.message : 'exception';
                        // this.$root.fn_showToast(toastMsg);
                    })
                }
            })

        },

        fn_SaveHeadShotSelection: function(){
            let self = this;
            this.loading = true;
            this.error = false;
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loading = false;
                }
                else{
                    var headersObj = {
                        headers: { 
                            'Authorization': 'bearer '+token,
                            }
                        };

                    axios.get(this.$root.baseUrl + "/api/mobileapp/headshots/" + this.MobileHeadShotSlotID + "/available/slot", headersObj, {timeout:30000})
                    .then(response => {
                        this.loading = false;
                        console.log(response);
                        this.snackbarMsg = false;
                        this.SlotSelectionDialog = false;
                        //this.$root.fn_postClickMetrics('Headshots', 'Save', this.MobileHeadShotSlotID);
                        this.fn_getDetails();
                        
                    }).catch(e => {
                        this.loading = false;
                        // this.error = true;
                        console.log(e);
                        if(e.response && e.response.data && e.response.data.Message && e.response.data.Message == 'Oops slot is full'){
                            //self.$root.fn_showToast('This timeslot is full, Please select other timeslot!');
                            self.slotFullSnackbarMsg = true;
                            self.fn_clearSlotTime();
                        }
                        // let toastMsg = e && e.message ? e.message : 'exception';
                        // this.$root.fn_showToast(toastMsg);
                    })
                }
            })
        },

        fn_clearSlotTime: function(){
            this.MobileHeadShotSlotID = null;
            this.fn_getSlotList();
        },

        fn_selectSlot: function(){
            this.slotDate = null;
            this.MobileHeadShotSlotID = null;
            this.SlotSelectionDialog = true;
            // this.$root.fn_postClickMetrics('Headshots', 'Click to Schedule', null);
            this.fn_getSlotList();
        },

        fn_getSlotList: function(){
            this.loading = true;
            this.error = false;
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loading = false;
                }
                else{
                    var headersObj = {
                        headers: { 
                            'Authorization': 'bearer '+token,
                            }
                        };

                    axios.get(this.$root.baseUrl + "/api/mobileapp/headshots/available/slot", headersObj, {timeout:30000})
                    .then(response => {
                        this.loading = false;
                        console.log(response);
                        this.snackbarMsg = false;
                        this.SlotList = response.data && response.data.SlotDateList && response.data.SlotDateList.length ? response.data.SlotDateList : [];
                    }).catch(e => {
                        this.loading = false;
                        console.log(e);
                    })
                }
            })

        },

        fn_getImage: function(imagename, imageurl){
            // this.$root.fn_postClickMetrics('Headshots', 'Download Photo', null);
            this.imageName = imagename;
            this.imageUrl = imageurl;
            if(this.$root.platform === 'ios'){
                this.fn_openImage();
            }
            else{
                this.$root.fn_checkStoragePermission(response=>{
                    if(JSON.parse(response)){
                        this.fn_openImage();
                    }
                    else{
                        this.$root.fn_showToast('Please allow storage permission');
                    }
                })
            }
        },

        fn_openImage: function(){
            let self = this;
            this.getData('GetImage', this.imageName, 
            {
                onSuccess: function(response){
                    console.log(response);
                    let isDownloaded = JSON.parse(response);
                    if(isDownloaded){
                        if(self.$root.platform === 'ios'){
                            self.fn_saveImage();
                        }
                        else{
                            self.fn_downloadImage();
                        }
                    }
                    else{
                        self.fn_downloadImage();
                    }
                    
                },
                onError: function(error){
                    console.log(error);
                    if(error.dataKey && !JSON.parse(error.dataKey)){
                        self.fn_downloadImage();
                    }
                }
            });
        },

        fn_downloadImage: function(){
            let self = this;
            let dataObj = {};
            dataObj.FileName = this.imageName;
            dataObj.FileUrl = this.$root.baseUrl + this.imageUrl;
            dataObj.DisplayName = this.imageName;
            this.$root.platform === 'android' ? dataObj.IsImageFile = true : '';
            this.downloading = true;
            this.getServerData('DownloadFile', dataObj, {
                onSuccess: function(response){
                    console.log(response);
                    self.downloading = false;
                    self.$root.platform === 'android' ? self.$root.fn_showToast('Added to Photos') : '';
                    if(self.$root.platform === 'ios'){
                        self.fn_saveImage();
                    }
                }, 
                onError: function(error){
                    console.log(error);
                    self.downloading = false;
                    self.$root.fn_showToast('exception');
                }, 
            });
        },

        fn_saveImage: function(){
            let self = this;
            this.getData('SaveImageToPhotoLibrary', this.imageName, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.$root.fn_showToast('Added to Photos');
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        async fn_photoAuthThumbnail() {

            // this.loading = true;
            // this.error = false;
            let self = this;

            if(this.headshotContent && this.headshotContent.Photos){
                for (let i = 0; i < this.headshotContent.Photos.length; i++) {
                    if(self.headshotContent.Photos[i].AttendeeThumbnailURL && !self.headshotContent.Photos[i].AttendeeThumbnailURL.startsWith('data:image/')){
                        try {
                            const token = await new Promise((resolve, reject) => {
                                this.$root.fn_getToken((token) => {
                                    if (token === 'error') {
                                        reject('Token error');
                                    } else {
                                        resolve(token);
                                    }
                                });
                            });

                            console.log(token + "Token Called");

                            const headersObj = {
                                headers: { 
                                    'Authorization': 'Bearer ' + token,
                                }
                            };

                            
                            console.log(self.$root.baseUrl);
                            const response = await axios.get(self.$root.baseUrl + self.headshotContent.Photos[i].AttendeeThumbnailURL, headersObj, {timeout: 30000});
                            
                            // Update album item with the retrieved data
                            console.log(response.data);
                            self.headshotContent.Photos[i].AttendeeThumbnailURL = 'data:image/png;base64,' + response.data;
                            
                            console.log(self.headshotContent.Photos[i].ImageUrl);
                            self.$forceUpdate();
                        } catch (e) {
                            self.error = true;
                            console.log(e);
                            let toastMsg = e && e.message ? e.message : 'exception';
                            self.$root.fn_showToast(toastMsg);
                            break; // Stop processing on error
                        }
                    }
                }
            }
            
            

            this.loading = false;
            this.error = false;
        },
    },
    mounted(){
        window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        this.$root.fn_postClickMetrics('Explore Tiles', 'Headshots', null);
        this.fn_getDetails();
        // this.fn_refresh();
    },

    computed:{
        SlotTimeList: function() {
            let SlotTimeList = []; 
            for (var i in this.SlotList) {
                if (this.SlotList[i].SlotDate == this.slotDate) {
                    return SlotTimeList = this.SlotList[i].SlotTimeList;
                }
            }
            return SlotTimeList
        },

        groupedSlots() {
            // const grouped = {};
            // this.SlotTimeList.forEach((slot) => {
            //     if (!grouped[slot.HourTime]) {
            //     grouped[slot.HourTime] = [];
            //     }
            //     grouped[slot.HourTime].push(slot);
            // });
            // console.log(grouped);
            // let GrouppedNavList = Object.values(grouped);
            // // for (var key in grouped) {
            // //     var tempObj = {};
            // //     tempObj.LeftNavList = grouped[key];
            // //     this.GrouppedNavList.push(tempObj);
            // // }

            // return GrouppedNavList;

            // Create an object to group slots by "HourTime"
            const grouped = {};
            // Create an array to maintain the order of items
            const order = [];

            for (const slot of this.SlotTimeList) {
                const hour = slot.HourTime;
                if (!grouped[hour]) {
                grouped[hour] = [];
                // Add the hour to the order array
                order.push(hour);
                }
                grouped[hour].push(slot);
            }

            // Create an array of grouped slots based on the order
            const groupedSlots = order.map((hour) => ({
                hour,
                slots: grouped[hour],
            }));

            return groupedSlots;

            
        }
            
    }
}
</script>