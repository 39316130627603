<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>Photo Gallery</v-toolbar-title>
        </v-toolbar>

        <div style="padding-bottom: 30px !important;" class="pl-2 pr-2 pt-2">
            <template v-if="error">
                <div class="placeholder-wrapper pa-4">
                    <div center-align>
                        <v-icon class="placeholder-icon">luggage</v-icon>
                        <p class="pt-4 light-opacity-color">Something went wrong. Please try again.</p>
                        <v-btn class="mt-4" outlined @click="fn_getAlbum"><v-icon left>refresh</v-icon> Retry</v-btn>
                    </div>
                </div>
            </template>

            <template v-if="loading">
                <div class="pa-8">
                    <v-img width="120" style="margin:auto" src="../../../assets/giphy.gif"></v-img>
                </div>
            </template>

            <template v-else>
                
                <v-layout row wrap ma-0>
                    <v-flex class="zindex2" xs6 sm6 md6 pa-2 v-for="(item, index) in albumItems" :key="index" @click="fn_photos(item.PhotoAlbumID)">
                        <v-img style="border-radius:10px" :src="item.ThumbnailUrl" lazy-src="https://picsum.photos/10/6?image=15" aspect-ratio="1" class="grey lighten-2" v-if="item.ThumbnailUrl">
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                        <v-img style="border-radius:10px" :src="$root.imagePlaceholder" aspect-ratio="1" v-else></v-img>
                        <div class="pt-1" style="font-size:0.875rem">{{item.Title}} ({{item.PhotosCount}})</div>
                    </v-flex>
                </v-layout>
                    
            </template>

            <template v-if="!loading && !error && (!albumItems || !albumItems.length)">
                <div class="placeholder-wrapper pa-4">
                    <div center-align>
                        <v-icon class="placeholder-icon">luggage</v-icon>
                        <p class="pt-4 light-opacity-color">No new albums</p>
                        <v-btn class="mt-4" outlined @click="fn_getAlbum"><v-icon left>refresh</v-icon> Reload</v-btn>
                    </div>
                </div>
            </template>
            
            
        </div>

        <div class="pcfooter_image">
            <img src="../assets/2024/PlatinumClub_SideWaveBG_1120x920.png">
        </div>
        <div class="pcfooter_wave_image">
            <img src="../assets/2024/PlatinumClub_BottomWave_1120x112.png">
        </div>
        <!-- <div class="pcfooter_image">
            <img v-show="0" src="../assets/PlatinumClub2023_FooterImage_420x200.png">
        </div> -->

    </div>
</template>

<script>

import axios from 'axios';

export default{
    data(){
        return{
            // snackbar:true
            bottomSheet:null,
            currentItem:null,
            albumItems:null,
            loading:false,
            error:false
            // albumItems:[
            //     {
            //         title:'All Photos',
            //         count:24,
            //         imgSrc:'https://picsum.photos/500/300?image=15',
            //         lazySrc:'https://picsum.photos/10/6?image=15'
            //     },
            //     {
            //         title:'Party',
            //         count:15,
            //         imgSrc:'https://picsum.photos/500/300?image=20',
            //         lazySrc:'https://picsum.photos/10/6?image=20'
            //     },
            //     {
            //         title:'Celebration',
            //         count:9,
            //         imgSrc:'https://picsum.photos/500/300?image=25',
            //         lazySrc:'https://picsum.photos/10/6?image=25'
            //     }
            // ]
        }
    },
    methods:{
        fn_photos: function(albumId){
            this.$router.push({name:'photos', params:{albumId:albumId}});
        },

        fn_getAlbum: function(){
            this.loading = true;
            this.error = false;
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loading = false;
                    this.error = true;
                    this.$root.fn_showToast('exception');
                }
                else{
                    var headersObj = {
                        headers: { 
                            'Authorization': 'bearer '+token,
                            }
                        };

                    axios.get(this.$root.baseUrl + "/api/mobileapp/albums", headersObj, {timeout:30000})
                    .then(response => {
                        this.loading = false;
                        // let data = JSON.parse(response);
                        console.log(response);
                        this.albumItems = response.data.albums;
                        this.fn_photoAuth();
                    }).catch(e => {
                        this.loading = false;
                        this.error = true;
                        console.log(e);
                        let toastMsg = e && e.message ? e.message : 'exception';
                        this.$root.fn_showToast(toastMsg);
                    })
                }
            })
            
        },

        async fn_photoAuth() {

            this.loading = true;
            this.error = false;
            let self = this;

            for (let i = 0; i < this.albumItems.length; i++) {
                try {
                    const token = await new Promise((resolve, reject) => {
                        this.$root.fn_getToken((token) => {
                            if (token === 'error') {
                                reject('Token error');
                            } else {
                                resolve(token);
                            }
                        });
                    });

                    console.log(token + "Token Called");

                    const headersObj = {
                        headers: { 
                            'Authorization': 'Bearer ' + token,
                        }
                    };


                    const response = await axios.get(this.$root.baseUrl + this.albumItems[i].ThumbnailUrl, headersObj, {timeout: 30000});
                    
                    // Update album item with the retrieved data
                    console.log(response.data);
                    self.albumItems[i].ThumbnailUrl = 'data:image/png;base64,' + response.data;
                    
                    console.log(this.albumItems[i].ThumbnailUrl);
                    this.$forceUpdate();
                } catch (e) {
                    self.error = true;
                    console.log(e);
                    let toastMsg = e && e.message ? e.message : 'exception';
                    self.$root.fn_showToast(toastMsg);
                    break; // Stop processing on error
                }
            }
            this.loading = false;
        },
    },
    mounted(){
        // window.js_globals.fn_initActions = this.fn_initActions;
        this.$root.fn_postClickMetrics('Explore Tiles', 'Photo Gallery', null);
        this.fn_getAlbum();
    }
}
</script>