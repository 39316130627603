<template>
    <div>
        <v-expansion-panels accordion focusable>
            <v-expansion-panel>
                <v-expansion-panel-header class="font-medium pa-4">Evening Shuttles to Boat Avenue/Porto de Phuket</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <!-- <div v-html="item.content" v-if="item.content"></div> -->
                    <v-card flat>
                        <div class="text-center shuttlesection_header" style="font-size: 13pt; line-height: 150%; justify-content: center;">Evening Shuttles<br>from Banyan Tree Lobby<br>to <a href="https://www.tripadvisor.com/RestaurantsNear-g1224250-d8611183-Boat_Avenue_Villa_Market-Choeng_Thale_Thalang_District_Phuket.html" target="_blank">Boat Avenue</a>/<a href="https://www.portodephuket.com/highlights/restaurant-and-cafe.html" target="_blank">Porto de Phuket</a></div>
                        <v-card-text class="pa-0">
                            <div>
                                <div class="pt-0">
                                    <div class="text-center">
                                        <b><u>Sunday, 19 November</u></b><br>18:00 - 23:00
                                    </div>
                                </div>
                                <div class="pt-4">
                                    <div class="text-center">
                                        <b><u>Monday, 20 November</u></b><br>18:30 - 23:00
                                    </div>
                                </div>
                                <div class="pt-4">
                                    <div class="text-center">
                                        <b><u>Wednesday, 22 November</u></b><br>18:00 - 23:00
                                    </div>
                                </div>
                                <div class="pt-4">
                                    <div class="text-center">
                                        Shuttles will run every 15-20 minutes.<br>Last shuttle departs Banyan Tree at 21:00.<br>Availability is on a first come, first served basis.
                                    </div>
                                </div>
                                <div class="pt-3">
                                    <div class="text-center">
                                        Shuttles will drop off and pick up at the same location in Porto de Phuket.
                                    </div>
                                </div>
                                <div class="pt-3">
                                    <div class="text-center" style="font-size: 10pt;">
                                        For shuttle assistance,<br>please call Yod at <a type="tel" href="tel:+66840452231">+66 84 045 2231</a>.
                                    </div>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header class="font-medium pa-4">Shuttle to Andamanda</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <!-- <div v-html="item.content" v-if="item.content"></div> -->
                    <v-card flat>
                        <div class="text-center shuttlesection_header pb-0" style="font-size: 13pt; line-height: 150%; justify-content: center;">Tuesday, 21 November</div>
                        <div class="text-center shuttlesection_header" style="font-size: 13pt; line-height: 150%; justify-content: center;">From Banyan Tree Lobby<br>to <a href="https://www.andamandaphuket.com/?utm_source=syn&utm_medium=ppc&gad=1&gclid=Cj0KCQjw4NujBhC5ARIsAF4Iv6f7GrGgGG8xmh_QhCtq28RMkl9USy-Um_dZcyAby_IvRoAMAEnqhG0aAhejEALw_wcB" target="_blank">Andamanda Water Park</a></div>
                        <v-card-text class="pa-0">
                            <div>
                                <table class="custom_table">
                                    <tr>
                                        <th>Depart Resort</th>
                                        <th>Arrive Andamanda</th>
                                    </tr>
                                    <tr>
                                        <td>9:30</td>
                                        <td>10:00</td>
                                    </tr>
                                    <tr>
                                        <td>10:00</td>
                                        <td>10:30</td>
                                    </tr>
                                    <tr>
                                        <td>10:30</td>
                                        <td>11:00</td>
                                    </tr>
                                </table>
                            </div>
                        </v-card-text>
                        <div class="text-center shuttlesection_header pt-7" style="font-size: 13pt; line-height: 150%; justify-content: center;">From <a href="https://www.andamandaphuket.com/?utm_source=syn&utm_medium=ppc&gad=1&gclid=Cj0KCQjw4NujBhC5ARIsAF4Iv6f7GrGgGG8xmh_QhCtq28RMkl9USy-Um_dZcyAby_IvRoAMAEnqhG0aAhejEALw_wcB" target="_blank">Andamanda Water Park</a><br>to Banyan Tree Resort</div>
                        <v-card-text class="pa-0">
                            <div>
                                <table class="custom_table">
                                    <tr>
                                        <th>Depart Andamanda</th>
                                        <th>Arrive Resort</th>
                                    </tr>
                                    <tr>
                                        <td>13:30</td>
                                        <td>14:00</td>
                                    </tr>
                                    <tr>
                                        <td>14:30</td>
                                        <td>15:00</td>
                                    </tr>
                                    <tr>
                                        <td>15:30</td>
                                        <td>16:00</td>
                                    </tr>
                                </table>

                                <div class="pt-3">
                                    <div class="text-center">
                                        Shuttle seat availability is on a first come, first served basis.
                                    </div>
                                </div>

                                <div class="pt-3">
                                    <div class="text-center">
                                        All costs (entrance, lockers, towels, etc.) at Andamanda are the responsibility of the individual.<br>Shuttles will drop off and pick up at the main entrance.

                                    </div>
                                </div>

                                <div class="pt-3">
                                    <div class="text-center" style="font-size: 10pt;">
                                        For shuttle assistance at Andamanda,<br>please call Yod at <a type="tel" href="tel:+66840452231">+66 84 045 2231</a>.
                                    </div>
                                </div>

                            </div>
                        </v-card-text>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header class="font-medium pa-4">Shuttle to Old Town Phuket</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <!-- <div v-html="item.content" v-if="item.content"></div> -->
                    <v-card flat>
                        <div class="text-center shuttlesection_header pb-0" style="font-size: 13pt; line-height: 150%; justify-content: center;">Tuesday, 21 November</div>
                        <div class="text-center pt-2 shuttlesection_header" style="font-size: 13pt; line-height: 150%; justify-content: center;">From Banyan Tree Lobby<br>to Old Town Phuket</div>
                        <v-card-text class="pa-0">
                            <div>
                                <table class="custom_table">
                                    <tr>
                                        <th>Depart Resort</th>
                                        <th>Arrive Old Town</th>
                                    </tr>
                                    <tr>
                                        <td>10:00</td>
                                        <td>10:30</td>
                                    </tr>
                                    <tr>
                                        <td>11:00</td>
                                        <td>11:30</td>
                                    </tr>
                                    <tr>
                                        <td>12:00</td>
                                        <td>12:30</td>
                                    </tr>
                                </table>
                            </div>
                        </v-card-text>
                        <div class="text-center shuttlesection_header pt-7" style="font-size: 13pt; line-height: 150%; justify-content: center;">From Old Town<br>to Banyan Tree Resort</div>
                        <v-card-text class="pa-0">
                            <div>
                                <table class="custom_table">
                                    <tr>
                                        <th>Depart Old Town</th>
                                        <th>Arrive Resort</th>
                                    </tr>
                                    <tr>
                                        <td>13:00</td>
                                        <td>13:30</td>
                                    </tr>
                                    <tr>
                                        <td>14:00</td>
                                        <td>14:30</td>
                                    </tr>
                                    <tr>
                                        <td>15:00</td>
                                        <td>15:30</td>
                                    </tr>
                                </table>

                                <div class="pt-3">
                                    <div class="text-center">
                                        Shuttle seat availability is on a first come, first served basis.
                                    </div>
                                </div>

                                <div class="pt-3">
                                    <div class="text-center">
                                        Shuttle will drop off and pick up at Romanee Road opposite Mongkhon Nimit Temple.
                                    </div>
                                </div>

                                <div class="pt-3">
                                    <div class="text-center" style="font-size: 10pt;">
                                        For shuttle assistance in Old Town,<br>please call Yod at <a type="tel" href="tel:+66840452231">+66 84 045 2231</a>.
                                    </div>
                                </div>

                            </div>
                        </v-card-text>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    export default{
        props:['content']
    }
</script>

<style>
table.custom_table{
    width: 100%;
}
table.custom_table, 
.custom_table th, 
.custom_table td{
    border:1px solid black;
    border-collapse: collapse;
}

.custom_table td{
    text-align: center;
}

.shuttlesection_header{
    font-size: 13pt;
    line-height: 150%;
    justify-content: center;
    font-weight: 500;
    letter-spacing: .0125em;
    padding: 16px;
    text-align: center;
}

/* .custom_table th, 
.custom_table td{
    padding-left: 16px;
    padding-right: 16px;
} */
  

</style>