<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div>
            <template v-if="loading">
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img :lazy-src="$root.imagePlaceholder" height="150" src="../assets/2024/Asia_MyActivitiesBanner_1125x450.png"></v-img>

                <overview :content="overviewContent" v-if="overviewContent"></overview>

                <template v-if="listContents && listContents.length">
                
                    <v-card class="ma-4" v-for="(item, index) in listContents" :key="index">
                        <div class="title-wrapper pl-4 pr-4 pt-2 pb-2">
                            <h3 class="title">{{item.Title}}</h3>
                        </div>
                        <v-divider class="mr-4 ml-4"></v-divider>
                        <v-card-text class="pb-4 short-card-text">
                            <div class="pb-3" v-if="item.ActivityDate"><strong>Date: </strong> {{item.ActivityDate | moment("ddd, MMM Do YYYY")}}</div>
                            <div class="pb-3" v-if="item.DisplayTime"><strong>Time: </strong> {{item.DisplayTime}}</div>
                            <div v-if="item.Attendees.length">
                                <div><strong>Attendees</strong></div>
                                <v-layout row wrap ma-0>
                                    <v-flex xs6 sm6 md6 pt-2 v-for="(attendee, attendeeIndex) in item.Attendees" :key="attendeeIndex">
                                        <div>{{attendee.AttendeeName}}</div>
                                        <div light-opacity-color>{{attendee.AttendeeType}}</div>
                                    </v-flex>
                                </v-layout>

                                <v-divider class="mt-4 mb-4"></v-divider>
                            </div>

                            <div class="pb-1"><strong>Schedule: </strong>{{item.ActivityDate | moment("ddd, MMM Do YYYY")}}</div>
                            <div v-html="item.Content"></div>
                        </v-card-text>

                        <v-card-actions @click="fn_openMoreDetails(item)">
                            <v-btn color="primary" text>View More</v-btn>
                            <v-spacer></v-spacer>

                            <v-btn icon>
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                        </v-card-actions>
                        
                    </v-card>             
                    
                </template>

                <div center-align light-opacity-color class="pa-8" v-if="!overviewContent && (!listContents || !listContents?.length) && !loading">
                    No Activities
                </div>
                

                <!-- <accordians :content="listContents" v-if="listContents && listContents.length"></accordians> -->
            </template>
            
        </div>

        <!-- <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getData('myactivities')">Refresh Now</v-btn>
            </template>
        </v-snackbar> -->

        <v-dialog v-model="contentDialog" scrollable transition="dialog-bottom-transition" content-class="gcasia-dialog" fullscreen persistent>
            <v-card flat v-if="currentItem">
                
                <v-card-title class="title pb-4 pl-4" style="flex-wrap:nowrap;word-break: break-word;">
                    <v-btn icon class="mr-4" @click="contentDialog=false">
                        <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
                    </v-btn>
                    {{currentItem.Title}}
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-tabs v-model="tabIndex" fixed-tabs background-color="#f0f0f0" :slider-color="$root.themeColor">
                        <v-tab>Details</v-tab>
                        <v-tab>Attendees</v-tab>

                        <v-tab-item>
                            <div class="pa-4">
                                <div class="pb-1"><strong>Date: </strong> {{currentItem.ActivityDate | moment("ddd, MMM Do YYYY")}}</div>
                                <div class="pb-1"><strong>Time: </strong> {{currentItem.DisplayTime}}</div>
                                <div v-html="currentItem.Content"></div>
                            </div>
                            
                        </v-tab-item>

                        <v-tab-item>
                            <div class="pt-4">

                                <div v-for="(item, index) in currentItem.Attendees" :key="index">

                                    <!-- <v-subheader class="title" color="grey">{{item.AttendeeName}}</v-subheader> -->
                                    <div class="title pl-4 pr-4 pt-2 pb-2">{{item.AttendeeName}}</div>

                                    <v-divider class="mx-4"></v-divider>

                                    <v-card class="ma-4" v-for="(question, questionIndex) in item.Questions" :key="questionIndex">
                                        <v-card-text class="">
                                            <p><strong>Q.  {{question.Question}}</strong></p>
                                            <p>A. {{question.Response}}</p>
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </div>
                        </v-tab-item>
                    </v-tabs>
                </v-card-text>
                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="contentDialog = false">close</v-btn>
                </v-card-actions> -->
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
// import axios from 'axios';

import overview from './sub_components/overview'
// import accordians from './sub_components/listcontents'

import {pageMixin} from './info_page_mixin'

export default{
    mixins:[pageMixin],
    components:{
        'overview':overview,
        // 'accordians':accordians
    },
    data(){
        return{
            contentDialog:false,
            currentItem:null,
            tabIndex:0
        }
    },
    methods:{
        fn_openMoreDetails: function(item){
            this.currentItem = item;
            this.tabIndex = 0;
            this.contentDialog = true;
        },

        // fn_updateAvailable: function(dataObj){
        //     let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
        //     if(updateObj.pageName==='myactivities'){
        //         if(this.overviewContent || (this.listContents && this.listContents.length)){
        //             this.snackbarMsg = true;
        //         }
        //         else{
        //             this.fn_getData('myactivities');
        //         }
        //     }
        // },

        fn_closeDialog: function(){
            if(this.contentDialog){
                this.contentDialog = false;
            }
            else{
                window.js_globals.fn_onBackPress = this.$root.fn_goBack;
                this.$root.fn_goBack();
            }
        }

    },
    mounted(){
        this.$root.fn_postClickMetrics('Explore Tiles', 'My Activities', null);
        // window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        window.js_globals.fn_onBackPress = this.fn_closeDialog;
        
        // this.fn_getData('myactivities');
        // this.fn_getData('myactivities', () => {
        //     if(localStorage.getItem('currentActivityId')){
        //         let activityIndex = this.listContents.findIndex(item=>{
        //             return item.ActivityID == localStorage.getItem('currentActivityId');
        //         });
        //         activityIndex != -1 ? this.fn_openMoreDetails(this.listContents[activityIndex]) : '';
        //         localStorage.removeItem('currentActivityId');
        //     } 
        // });

        this.fn_getPageDataFromServer('myactivities', () => {
            if(localStorage.getItem('currentActivityId')){
                let activityIndex = this.listContents.findIndex(item=>{
                    return item.ActivityID == localStorage.getItem('currentActivityId');
                });
                activityIndex != -1 ? this.fn_openMoreDetails(this.listContents[activityIndex]) : '';
                localStorage.removeItem('currentActivityId');
            } 
        });
    }
}
</script>