<template>
    <div>
        <v-toolbar flat class="pl-2">
            <v-toolbar-title style="line-height:20px;padding-top: 6px;">
            <!-- <v-toolbar-title> -->
                <strong>LOGIN</strong>
                <!-- <div style="font-size:small">Enter your email id to proceed or scan qr code</div> -->
                <!-- <div font-small>Scan qr code or login via email &amp; invitation code</div> -->
                <!-- <div font-small>Enter mobile number to Login</div> -->
                <div font-small>Enter email &amp; code or scan QR code to Login</div>
            </v-toolbar-title>
        </v-toolbar>

        <div class="pa-6 pt-8">

            <v-card flat>
                 <v-form>    
                    <!-- <label for="email" font-small>Enter email id</label>
                    <v-text-field :disabled="loading" hide-details id="email" name="email" type="email" v-model="emailId" class="pa-0" required @input="fn_validateEmail"></v-text-field>

                    <span font-small style="color:red" v-if="!isValid">Please enter a valid email</span>

                    <v-btn class="mt-6" :disabled="loading" style="color:#ffffff" block color="blue-grey darken-3" height="48" @click="fn_sendOTP">
                        {{loading ? 'Sending OTP...' : 'Continue'}}
                    </v-btn>

                    <v-divider class="mx-16 mt-8"></v-divider> -->

                    <div center-align style="background:#ECEFF1;width: 120px; height:120px;margin:auto;border-radius:50%;">
                        <v-img src="../../assets/AppIcon_other_120x120.png"></v-img>
                        <!-- <div class="mt-4" style="color:#37474F"><strong>CLUB TRIP</strong></div> -->
                    </div>
                    
                    <div class="mt-4 center-align" style="color:#37474F"><strong>CLUB TRIP</strong></div>

                    <div class="mt-8">


                        <!-- <div>
                            <p>Enter your mobile number to continue</p>
                            <p>We will send a code on mentioned mobile number</p>
                        </div> -->

                        <v-card class="pa-4">
                            <!-- <v-form>
                                <div>
                                    <p class="font-weight-medium" style="font-size: 15pt;">We will send a code on mentioned mobile number</p>
                                </div>
                                <div class="mt-4 login-form">
                                    <label for="email" font-small>Enter your mobile number to continue</label>
                                    <v-text-field prefix="+91" hide-details :disabled="loading" type="tel" name="code" v-model="code" class="pa-0" required @input="fn_isCodeValid" @focus="isActive = true" @blur="isActive = false"></v-text-field>
                                </div>

                                
                                <v-btn class="mt-8" style="color:#ffffff" block color="blue-grey darken-3" height="48" @click="fn_getHashKey" :disabled="loading">
                                    {{loading ? 'Please wait...' : 'Continue'}}
                                </v-btn>

                            </v-form> -->
                            <v-form>
                                <label for="email" font-small>Email</label>
                                <v-text-field :disabled="loading" hide-details id="email" name="email" type="email" v-model="emailId" class="pa-0" required @focus="isActive = true" @blur="isActive = false" @input="fn_validateEmail"></v-text-field>
                                <span font-small style="color:red" v-if="!isValid">Please enter a valid email</span>


                                <div class="mt-8">
                                    <label for="email" font-small>Invitation Code</label>
                                    <v-text-field hide-details :disabled="loading" type="tel" name="code" v-model="code" class="pa-0" required @focus="isActive = true" @blur="isActive = false" @input="fn_isCodeValid"></v-text-field>
                                    <span font-small style="color:red" v-if="!isCodeValid">Please enter 6 digit invitation code</span>
                                </div>

                                <v-btn class="mt-8" style="color:#ffffff" block color="blue-grey darken-3" height="48" @click="fn_getDeviceDetailsThroughEmail" :disabled="loading || !isCodeValid">
                                    {{loading ? 'Please wait...' : 'Verify'}}
                                </v-btn>
                            </v-form>
                        </v-card>

                        <!-- <v-btn outlined :disabled="loading" @click="fn_checkCameraPermission" style="color:#ffffff" block color="blue-grey darken-3" height="48">
                            <v-icon left>qr_code</v-icon> Enter Mobile Number
                        </v-btn> -->

                        <v-divider class="mx-16 mt-8"></v-divider>
                        

                        <v-btn outlined class="mt-8" :disabled="loading" @click="fn_checkCameraPermission" style="color:#ffffff" block color="blue-grey darken-3" height="48">
                            <v-icon left>qr_code</v-icon> {{loginViaQR && loading ? 'Verifying your details...' : 'Scan QR Code'}}
                        </v-btn>

                        <!-- <v-divider class="mx-16 mt-8"></v-divider>

                        <v-btn text text-transform-none small :disabled="loading" @click="fn_loginViaEmail" class="mt-2" block color="blue-grey darken-3" height="48">
                            Login via Email
                        </v-btn> -->
                    </div>

                    <!-- <div class="mt-8">
                        <label for="invitation_code" font-small>Login through Invitation Code</label>
                        <v-btn :disabled="loading" @click="fn_checkCameraPermission" class="mt-2" outlined dark block color="blue-grey darken-3" height="48">
                            Continue
                        </v-btn>
                    </div> -->

                    <div class="pt-4 font-small" :style="{ position: isActive ? 'unset' : 'fixed'}" style="position:fixed;bottom: 16px;">By login, I accept <span @click="$root.fn_openExternalPage('https://clubtrip.app/terms')"><strong>Terms &amp; Conditions</strong></span> & <span @click="$root.fn_openExternalPage('https://clubtrip.app/policy')"><strong>Privacy Policy</strong></span></div>

                </v-form>
            </v-card>

            <!-- <v-btn color="success" @click="$router.go(-1)">text</v-btn> -->
           

            
        </div>
    </div>
</template>

<script>
// import axios from 'axios';
import {cometchatLogin} from '../../cometchat_login.js'

export default{
    data(){
        return{
            emailId:null,
            isValid:true,
            loading:false,
            loginViaQR:false,
            isCodeValid:false,
            code: null,
            isActive: false,
            CountryCode: '+91 '
        }
    },
    methods: {
        // fn_initActions: function(tripId, notificationId){
        fn_initActions: function(tripId, alertObj){
            // var self = this;
            console.log('Im init');
            console.log(tripId);
            console.log(alertObj);
            this.fn_getEnvironment();
           
            // this.getData('Environment', null, 
            //     {
            //       onSuccess: function(response){
            //           console.log(response);
            //           localStorage.setItem('Envirenment', response);
            //           self.$root.Envirenment = localStorage.getItem('Envirenment');
            //           console.log(this);
            //           console.log(self.$root.Envirenment);
                      
            //           //_envirenment = response;
            //       },
            //       onError: function(error){
            //           console.log(error);
            //       }
            //     }
            // ); 


            // this.$root.tripSiteId = tripId ? tripId : null;
            localStorage.removeItem('lastactivetab');
            
            console.log(this.$root.Envirenment);
            console.log(localStorage.getItem('Envirenment'));
            if(tripId){
                this.$root.tripSiteId = tripId;
                let notificationObj = null;
                if(alertObj && !alertObj.message){
                    notificationObj = this.$root.platform === 'ios' ? alertObj : JSON.parse(alertObj);
                }
                if(notificationObj && notificationObj.tripsiteid){
                    if(Number(notificationObj.tripsiteid) != Number(this.$root.tripSiteId)){
                        console.log('switch trip');
                        this.$root.notificationObj = notificationObj;
                        this.$root.alertDialog = true;
                    }
                    else{
                        notificationObj.notificationid ? localStorage.setItem('n_id', notificationObj.notificationid) : '';
                        notificationObj.pagename ? localStorage.setItem('p_name', notificationObj.pagename) : '';
                    }
                }

                    cometchatLogin.fn_initCometChat(this, tripId, ()=>{
                        // this.$root.tripSiteId = tripId;
                        localStorage.removeItem('last_schedule_active_tab_'+tripId);
                        this.$root.fn_getLocalData('AttendeeInfo', response=>{
                            this.$root.tripOnLoadData.AttendeeInfo = response
                        });
                        console.log(alertObj);
                        if(alertObj){
                            // let notificationObj = null;
                            if(alertObj.message){
                                notificationObj = JSON.parse(alertObj.message);
                            }
                            // else{
                            //     notificationObj = this.$root.platform === 'ios' ? alertObj : JSON.parse(alertObj);
                            // }
                            console.log(notificationObj);
                            // if(notificationObj.tripsiteid){
                            //     if(Number(notificationObj.tripsiteid) != Number(this.$root.tripSiteId)){
                            //         console.log('switch trip');
                            //         this.$root.notificationObj = notificationObj;
                            //         this.$root.alertDialog = true;
                            //     }
                            //     else{
                            //         notificationObj.notificationid ? localStorage.setItem('n_id', notificationObj.notificationid) : '';
                            //         notificationObj.pagename ? localStorage.setItem('p_name', notificationObj.pagename) : '';
                            //     }
                            // }
                            
                            if(notificationObj && notificationObj.category && notificationObj.category == "message"){
                                this.$root.fn_openChatView('notification');
                            }
                            
                        }
                    })
                
            }    

            // saving notification id to route notification display page if tap direcly on notification
            // notificationId ? localStorage.setItem('n_id', notificationId) : '';

            // if(tripId == 7){
            //     this.$router.replace({path:'/platinumclub'});
            // }
            // else if(tripId == 8){
            //     this.$router.replace({path:'/gcamericas'});
            // }
            // else if(tripId == 9){
            //     this.$router.replace({path:'/gcasia'});
            // }
            // else if(tripId == 10){
            //     this.$router.replace({path:'/gcemea'});
            // }
            // else if(tripId == 11){
            //     this.$router.replace({path:'/demotrip'});
            // }
            if(tripId && tripId < 16){
                this.$root.fn_pageLoadComplete();
                this.$router.replace({path:'/trips'});
            }

            if(tripId == 20){
                this.$router.replace({path:'/platinumclub'});
            }
            else if(tripId == 17){
                this.$router.replace({path:'/gcamericas'});
            }
            else if(tripId == 18){
                this.$router.replace({path:'/gcasia'});
            }
            else if(tripId == 19){
                this.$router.replace({path:'/gcemea'});
            }
            else if(tripId == 16){
                this.$router.replace({path:'/pinnacle'});
            }
            else if(tripId == 11){
                this.$router.replace({path:'/demotrip'});
            }
            else{
                this.$root.fn_pageLoadComplete();
            }
            
        },

        fn_validateEmail: function(value){
            let emailCheck=/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
            this.isValid = emailCheck.test(value);
        },

        fn_loginViaEmail: function(){
            this.$router.push({name:'verifyotp'});
        },

        fn_loginViaPhoneNumber: function(){
            this.$router.push({name:'verifymobileotp', query: { mobile_number: this.code }});
        },

        // fn_validateEmail: function(value){
        //     let emailCheck=/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
        //     this.isValid = emailCheck.test(value);
        // },

        // fn_sendOTP: function(){

        //     if(!this.isValid){
        //         this.$root.fn_showToast("Please enter a valid email");
        //         return;
        //     }

        //     if(!navigator.onLine){
        //         this.$root.fn_showToast('nointernet');
        //         return;
        //     }

        //     this.loading = true;
        //     this.loginViaQR = false;
            
        //     let self = this;
        //     let dataObj = {};
        //     dataObj.EmailAddress = this.emailId;
        //     this.postServerData('SendOTP', dataObj, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 self.$root.fn_showToast("OTP Sent");
        //                 self.$router.push({name:'verifyotp', params:{requestId:response.RequestID, email:self.emailId}});
        //                 self.loading = false;
        //             }, 
        //             onError: function(error){
        //                 console.log(error);
        //                 self.loading = false;
        //                 self.$root.fn_showToast('exception');
        //             }, 
        //         }
        //     );
        // },

        // Login through QR Code

        fn_checkCameraPermission: function(){
            let self = this;
            let itemId = this.$root.platform === 'ios' ? null : 'CameraPermission';
            this.getData('CheckCameraPermission', itemId, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.fn_scanQR();
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            ); 
        },
        
        fn_scanQR: function(){
            console.log('open scanner');
            this.getData('OpenScanner', null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_getDeviceDetails: function(queryString){
            console.log(queryString);
            this.loginViaQR = true;
            this.loading = true;
            this.$root.fn_showToast('Verifying your details');
            let self = this;
            this.getData("DeviceDetail", null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        
                        if(!navigator.onLine){
                            this.showToast("Internet not available");
                            return;
                        }
                        
                        let qrJson = JSON.parse(queryString);
                        delete response.DeviceID;
                        let dataObj = {};
                        dataObj.EmailAddress = qrJson.Email;
                        dataObj.QRCode = qrJson.InvitationCode.substring(qrJson.InvitationCode.lastIndexOf('=')+1);
                        dataObj.DeviceDetails = response;

                        self.postServerData('ValidateUserThroughDOB', dataObj, 
                            {
                                onSuccess: function(response){
                                    console.log('Validate Success')
                                    console.log(response);
                                    if(!response.DOBApplicable){
                                        self.fn_verifyQRCode(queryString, dataObj.DeviceDetails);
                                    }
                                    else{
                                        self.$router.push({name:'verifydob', params:{itemObj:dataObj}});   
                                    }
                                }, 
                                onError: function(error){
                                    console.log('Validate Falied')
                                    console.log(error);
                                }, 
                            }
                        );
                    },
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        self.$root.fn_showToast('exception');
                    }
                }
            );
        },

        fn_getDeviceDetailsThroughEmail: function(){
            if(!this.isValid){
                this.$root.fn_showToast('Please enter a valid email');
                return;
            }

            let self = this;
            this.loading = true;
            this.getData("DeviceDetail", null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        // self.fn_verifyOTP(response);
                        self.fn_verifyInvitationCode(response);
                    },
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        self.$root.fn_showToast('exception');
                    }
                }
            );
        },
        // fn_verifyQRCode: function(qrcode, deviceDetails){
        fn_verifyQRCode: function(queryString, deviceDetails){
            if(!navigator.onLine){
                this.$root.fn_showToast("nointernet");
                this.loading = false;
                return;
            }

            delete deviceDetails.DeviceID;
            
            let self = this;
            let dataObj = {};
            // dataObj.QRCode = qrcode;
            dataObj.DeviceDetails = deviceDetails;

            let qrJson = JSON.parse(queryString);
            dataObj.EmailAddress = qrJson.Email;
            dataObj.QRCode = qrJson.InvitationCode.substring(qrJson.InvitationCode.lastIndexOf('=')+1);

            console.log(JSON.stringify(dataObj));
            
            this.postServerData('VerifyQRCode', dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.fn_loginToFirebase(response);
                        
                    }, 
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        // self.$root.fn_showToast("exception");
                        // self.$root.fn_showToast(error.Message);
                        let toastMsg = error && error.Message ? error.Message : 'exception';
                        self.$root.fn_showToast(toastMsg);
                    }, 
                }
            );
        },

        fn_loginToFirebase: function(verifyResponseObj){
            let self = this;
            this.getData("FirebaseLoginUsingCustomToken", verifyResponseObj.Token, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        // self.fn_confirmLogin(verifyResponseObj);
                        self.$router.replace({name:'trips'});
                    },
                    onError: function(error){
                        console.log(error);
                        self.loading = false
                        self.$root.fn_showToast('exception');
                    }
                }
            );
        },

        fn_getEnvironment: function(){
            var self = this;
            console.log(localStorage.getItem('Envirenment'));
            if(localStorage.getItem('Envirenment')){
                this.$root.Envirenment = localStorage.getItem('Envirenment');
            }
            this.getData('Environment', null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        localStorage.setItem('Envirenment', response);
                        self.$root.Envirenment = localStorage.getItem('Envirenment');
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
            console.log(this.$root.Envirenment); 
        },

        fn_isCodeValid: function(){
            this.isCodeValid = !this.code || this.code.length != 6 ? false : true;
        },

        // fn_sendOtpold: function(){
        //     this.loading = true;
        //     console.log("token");   
        //     var headersObj = {
        //         headers: {
        //             'MobileAppKey': '0193c477-8f0b-4a50-970a-e3be5cc6bf99'
        //         }
        //     };
        //     console.log("Pass ");
        //     let self = this;
        //     let dataObj = {};
        //     dataObj.HashKey = "AAAAAAAAAAA";
        //     dataObj.PhoneNumber = this.CountryCode + this.code;
        //     dataObj.RequestType = 1;
        //     console.log(headersObj);
        //     axios.post(this.$root.baseUrl + "/api/mobileapp/phoneotp/sent", dataObj, headersObj, {timeout:30000})
        //     .then(response => {
        //         this.loading = false;
        //         console.log(response);
        //         if(response.data.IsVerify){
        //            this.$router.push({
        //                 name:'verifymobileotp',
        //                 params: { requestId: response.data.RequestID },
        //                 query: { mobile_number: self.code }
        //             });  
        //         }
                
        //     }).catch(e => {
        //         console.log(e);
        //         this.$root.fn_showToast("Enter valid phone number!");
        //         this.loading = false;
        //     })
                

        // },

        fn_getHashKey: function(){
            if(!this.isValid){
                this.$root.fn_showToast('Please enter a valid mobile number');
                return;
            }

            let self = this;
            this.loading = true;
            if(this.$root.platform === 'android'){
                this.getData("GetHashKey", null, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.fn_sendOtp(response);
                        },
                        onError: function(error){
                            console.log(error);
                            self.loading = false;
                            self.$root.fn_showToast('exception');
                        }
                    }
                );
            }
            else
                this.fn_sendOtp('c8UbZ0dRt/l');
            
        },

        fn_sendOtp: function(hashkey){
            if(!navigator.onLine){
                this.showToast("Internet not available");
                return;
            }
            this.loading = true;
            let self = this;
            let dataObj = {};
            console.log(hashkey);
            dataObj.HashKey = hashkey;
            dataObj.PhoneNumber = this.CountryCode + this.code;
            dataObj.RequestType = 1;
            console.log(dataObj);
            this.postServerData('SendPhoneOTP', dataObj, 
                {
                    onSuccess: function(response){
                        self.loading = false;
                        console.log(response);
                        console.log("Otp Success");
                        if(response.IsVerify){
                            self.$router.push({
                                name:'verifymobileotp',
                                params: { requestId: response.RequestID },
                                query: { mobile_number: self.code }
                            });  
                        }
                    }, 
                    onError: function(error){
                        console.log(error);
                        console.log("Otp Error");
                        self.$root.fn_showToast("Enter valid phone number!");
                        self.loading = false;
                    }, 
                }
            );
        },

        fn_verifyInvitationCode: function(deviceDetails){
            if(!navigator.onLine){
                this.$root.fn_showToast("nointernet");
                return;
            }

            this.loading = true;
            
            let self = this;
            let dataObj = {};
            dataObj.EmailAddress = this.emailId;
            dataObj.Code = this.code;
            dataObj.DeviceDetails = deviceDetails;

            this.postServerData('VerifyInviteCode', dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.requestId = response.RequestID;
                        // self.fn_setTimer();
                        self.fn_loginToFirebase(response);
                        // self.loading = false;
                    }, 
                    onError: function(error){
                        console.log(error);
                        self.loading = false;
                        // self.$root.fn_showToast(error.Message);
                        let toastMsg = error && error.Message ? error.Message : 'exception';
                        self.$root.fn_showToast(toastMsg);
                        // self.fn_clearTimer();
                        // self.$root.fn_showToast('exception');
                    }, 
                }
            );
        }

        // fn_confirmLogin: function(verifyResponseObj){
        //     this.$root.fn_getToken(token=>{
        //         if(token == 'error'){
        //             this.loading = false;
        //             this.$root.fn_showToast('exception');
        //         }
        //         else{
        //             let self = this;
        //             let dataObj = {};
        //             dataObj.TripSiteID = Number(verifyResponseObj.TripSiteID);
        //             dataObj.DeviceID = verifyResponseObj.DeviceID;
        //             dataObj.Token = token;
                    
        //             this.postServerData('ConfirmLogin', dataObj, 
        //                 {
        //                     onSuccess: function(response){
        //                         console.log(response);
        //                         self.fn_reLoginToFirebase(response);
        //                     }, 
        //                     onError: function(error){
        //                         console.log(error);
        //                         self.loading = false;
        //                         self.$root.fn_showToast("exception");
        //                     }, 
        //                 }
        //             );
        //         }
        //     })
        // },

        // fn_reLoginToFirebase: function(confirmationObj){
        //     let self = this;
        //     let dataObj = {};
        //     dataObj.TripSiteID = confirmationObj.TripSiteID;
        //     dataObj.UserType = confirmationObj.UserType ? confirmationObj.UserType : 0;
        //     dataObj.DeviceID = confirmationObj.DeviceID;
        //     dataObj.Token = confirmationObj.Token;

        //     this.postData("FirebaseReLoginUsingCustomToken", dataObj, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 self.fn_enterTrip(confirmationObj.TripSiteID);
        //                 console.log("Logged in Successfully");
        //             },
        //             onError: function(error){
        //                 console.log(error);
        //                 self.loading = false;
        //                 self.$root.fn_showToast("exception");
        //             }
        //         }
        //     );
        // },

        // fn_enterTrip: function(tripId){
        //     let self = this;
        //     let pcgcTripId = tripId.toString();
        //     this.getData('SwitchTrip', pcgcTripId, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 self.loading = false;
        //                 localStorage.removeItem('lastactivetab');
        //                 self.$root.tripSiteId = tripId;
        //                 if(tripId === 7){
        //                     self.$router.replace('/platinumclub');
        //                 }
        //                 else if(tripId === 8){
        //                     self.$router.replace('/gcamericas');
        //                 }
        //                 else if(tripId === 9){
        //                     self.$router.replace('/gcasia');
        //                 }
        //                 else if(tripId === 10){
        //                     self.$router.replace('/gcemea');
        //                 }
        //                 else if(tripId === 11){
        //                     self.$router.replace({path:'/demotrip'});
        //                 }
        //             },
        //             onError: function(error){
        //                 console.log(error);
        //                 self.loading = false;
        //                 self.$root.fn_showToast("exception");
        //             }
        //         }
        //     );
        // },

    },
    mounted() {
        // if(this.$root.Envirenment != 3){
        //     cometchatLogin.fn_initCometChat();
        // }
        window.js_globals.fn_initActions = this.fn_initActions;
        window.js_globals.fn_QrCodeReceived = this.fn_getDeviceDetails;

        if(localStorage.getItem('reload_trip_id')){
            let tripId = localStorage.getItem('reload_trip_id');
            localStorage.removeItem('reload_trip_id');
            this.fn_initActions(Number(tripId));
        }
        
    },
}

</script>

<style>
.login-form .v-input .v-input__slot .v-text-field__prefix {
    color: rgba(0, 0, 0, .87);
    border-right: 1px solid #ddd;
    padding-right: 8px;
    margin-right: 8px;
}
</style>