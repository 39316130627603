<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div>
            <template v-if="loading">
                <!-- <a type="button" href="./../assets/platinumclub/MC_PC_2022_DA_Monaco_map.pdf" target="_blank">View Map</a> -->
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/2024/Americas_KeyLocationsBanner_1125x450.png"></v-img>

                <v-card flat>
                    <div>
                        <v-card-title class="pb-2">Grand Wailea Resort</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">3850 Wailea Alanui Dr,<br>Wailea, HI 96753</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Grand Wailea Resort', 20.6843845, -156.440565)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Grand Wailea Resort', 20.68455720705299, -156.4403504390851)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>

                    <div>
                        <v-card-title class="pb-2">Kahului  Airport</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">1 Keolani Pl,<br>Kahului, HI 96732</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Kahului  Airport', 20.8932861, -156.4375287)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Kahului  Airport', 20.895079079717007, -156.43607439445435)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">The Shops at Wailea</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">3750 Wailea Alanui Dr,<br>Wailea, HI 96753</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('The Shops at Wailea', 20.6871133, -156.4404198)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('The Shops at Wailea', 20.687155140256788, -156.4402241521658)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Wailea Golf Course</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">100 Wailea Ike Drive,<br>Wailea, Maui, Hawaii 96753</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Wailea Golf Course', 20.6656137, -156.4355385)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Wailea Golf Course', 20.675167972645827, -156.43435158381402)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Hawaiian Style Surf School</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">1900 S Kihei Rd,<br>Kihei, HI 96753</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Hawaiian Style Surf School', 20.7306582, -156.3567707)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Hawaiian Style Surf School', 20.73169904913623, -156.45287905126534)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Kai Kanani Sunset Sail</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">5400 Makena Alanui,<br>Kihei, HI 96753</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Kai Kanani Sunset Sail', 20.6459026, -156.4411487)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Kai Kanani Sunset Sail', 20.646683556779745, -156.44128495181863)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>

                    <div>
                        <v-card-title class="pb-2">Kanoi Coast Rafting (Sea Caves)</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">2920 S Kihei Rd,<br>Kihei, HI 96753</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Kanoi Coast Rafting (Sea Caves)', 20.705482, -156.4451319)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Kanoi Coast Rafting (Sea Caves)', 20.708556357477, -156.44545734845343)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>

                    <div>
                        <v-card-title class="pb-2">Haleakala National Park</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">Haleakala Hwy,<br>Kula, Hawaii 96790</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Haleakala National Park', 20.7073079, -156.1541748)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Haleakala National Park', 20.72724697296937, -156.15667227086348)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>

                    <div>
                        <v-card-title class="pb-2">Maui Ocean Center, The Aquarium of Hawaii</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">192 Maalaea Rd,<br>Wailuku, HI 96793</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Maui Ocean Center, The Aquarium of Hawaii', 20.792731, -156.512228)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Maui Ocean Center, The Aquarium of Hawaii', 20.792973185174798, -156.51217232335836)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>

                </v-card>

                <!-- <v-card class="ma-4">
                    <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/GCAmercasResortMap_400x220.png"></v-img>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <a type="maplink" class="pr-2" href="/assets/gcasia/resort_map_9.pdf" target="_blank">View Map</a>
                    </v-card-actions>
                </v-card> -->

                <!-- <overview :content="overviewContent" v-if="overviewContent"></overview>

                <accordians :content="accordianContent" v-if="accordianContent"></accordians> -->

            </template>
            
        </div>

        <!-- <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getData('hotel')">Refresh Now</v-btn>
            </template>
        </v-snackbar> -->

    </div>
</template>

<script>
// import overview from './sub_components/overview'
// import accordians from './sub_components/accordians'

import {pageMixin} from './info_page_mixin'

export default{
    mixins:[pageMixin],
    // components:{
    //     'overview':overview,
    //     'accordians':accordians
    // },
    methods:{
        // fn_updateAvailable: function(dataObj){
        //     let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
        //     if(updateObj.pageName==='hotel'){
        //         if(this.overviewContent || this.accordianContent){
        //             this.snackbarMsg = true;
        //         }
        //         else{
        //             this.fn_getData('hotel');
        //         }
        //     }
        // }

        fn_openLocationOnMap: function(placeName, latitude, longitude){
            let dataObj = {};
            dataObj.PlaceName = placeName;
            dataObj.Latitude = latitude;
            dataObj.Longitude = longitude;
           
            this.postData('OpenMaps', dataObj, 
                {
                    onSuccess: function(response){
                        console.log("Success Map");
                        console.log(response);
                    },
                    onError: function(error){
                        console.log("Error Map");
                        console.log(error);
                    }
                }
            );
        }
    },
    mounted(){
        this.$root.fn_postClickMetrics('Explore Tiles', 'Key Locations', null);
        // window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        this.fn_getPageDataFromServer('keylocations');
    }
}
</script>