<template>
  <v-app>
    <v-main>
        <div :class="$root.selectedTrip ? $root.selectedTrip : ''">
            <transition :name="$route.meta.transitionName" mode="out-in" appear>
                <router-view />
            </transition>
            
            <v-dialog v-model="$root.alertDialog" no-click-animation persistent max-width="290">
                <v-card>
                    <v-card-title class="text-h5">Switch Trip</v-card-title>
                    <v-card-text>You need to switch your trip to view more details</v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-layout row wrap ma-0 center-align>
                            <v-flex xs6 sm6 md6 style="border-right:1px #ddd solid">
                                <v-btn text @click="$root.alertDialog = false">Close</v-btn>
                            </v-flex>
                            <v-flex xs6 sm6 md6>
                                <v-btn color="primary" text @click="$root.fn_routeToTrips">Okay</v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="$root.loader" persistent width="300">
                <v-card>
                    <v-card-text class="pt-4">Downloading...
                        <v-progress-linear indeterminate color="primary" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-snackbar v-model="$root.appsnackbar" :timeout="-1">
                Update app to use chat feature
                <template v-slot:action="{ attrs }">
                    <v-btn v-bind="attrs" text small color="yellow darken-3" @click.native="$root.appsnackbar = false">Later</v-btn>
                    <v-btn v-bind="attrs" text small color="yellow darken-3" @click.native="openAppPlayStore">Update Now</v-btn>
                </template>
            </v-snackbar>

        </div>
    </v-main>

    <v-dialog v-model="dialog_alert" persistent max-width="290">
        <v-card style="border-radius: 5px;">
            <v-card-title class="pt-4 pl-4 pr-4" font-medium noshadow>{{ alertDataObj.title }}</v-card-title>
            <v-card-text class="pl-4 pr-4 pb-4">
            <div>{{ alertDataObj.description }}</div>
            </v-card-text>
            <v-card-actions>
            <v-layout col wrap ma-0>
                <v-flex xs12 sm12 md12 center-align>
                    <v-btn color="primary" text @click="alertDataObj.actionMethod">{{ alertDataObj.okBtnText }}</v-btn>
                </v-flex>
                <v-flex xs12 sm12 md12 mt-1 center-align>
                    <v-btn color="primary" text @click="alertDataObj.closeDialog">{{ alertDataObj.cancelBtnText }}</v-btn>
                </v-flex>
                <v-flex xs12 sm12 md12 mt-1 center-align v-if="alertDataObj.hasThreeBtns">
                    <v-btn color="red" text @click="alertDataObj.thirdBtnAction">{{ alertDataObj.thirdBtnText }}</v-btn>    
                </v-flex>
            </v-layout>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_permission" persistent max-width="290">
        <v-card>
          <v-card-text class="pa-4">
            <div v-if="$root.permissionType=='contact'">
                Allow ClubTrip App to access your contacts.
              </div>
              <div v-if="$root.permissionType=='storage'">
                Allow ClubTrip App to access your device's storage, photos, media and files.
              </div>
              <div v-if="$root.permissionType=='camera'">
                Allow ClubTrip App to access your device's camera.
              </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="blue darken-1" text @click="fn_closeDialogPermission">Cancel</v-btn>
            <v-btn small color="blue darken-1" text @click.native="fn_goToSettings">Go To Settings</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_update" persistent max-width="290">
        <v-card>
          <v-card-text class="pa-4">
            <div>
                You have an update in app! Do you want to refresh app?
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="blue darken-1" text @click="fn_closeDialogUpdate">Cancel</v-btn>
            <v-btn small color="blue darken-1" text @click.native="fn_refresApp">Refresh App</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
// import home from './components/goldclub/home';

export default {
  name: 'App',
  data(){
    return{
        alertDataObj:{'title':null, 'description':null, 'okBtnText':'Ok', 'cancelBtnText':'cancel', 'hasThreeBtns':false, 'thirdBtnText':null},
        dialog_alert: false,
        dialog_permission: false,
        dialog_update: false
    }
  },

  methods:{
    openAppPlayStore: function(){
        if(this.$root.platform == 'ios'){
            this.$root.fn_openExternalPage('https://apps.apple.com/in/app/clubtrip/id1645332469');
        }
        else{
            this.$root.fn_openExternalPage('https://play.google.com/store/apps/details?id=com.jm.clubtrips');
        }
    },

    fn_closeDialogPermission: function(){
        this.dialog_permission = false;
        window.js_globals.fn_onBackPress = window.js_globals.fn_onBackPressPrevious;
    },

    fn_closeDialogUpdate: function(){
        this.dialog_update = false;
        window.js_globals.fn_onBackPress = window.js_globals.fn_onBackPressPrevious;
    },

    fn_closeDialogAlert: function(){
        this.dialog_alert = false;
        window.js_globals.fn_onBackPress = window.js_globals.fn_onBackPressPrevious;
    },
    fn_doNotAskAgain: function(){
        localStorage.setItem('donotaskfornotification', 1);
        this.fn_closeDialogAlert();
    },
    fn_goToSettings: function(){
        this.fn_closeDialogPermission();
        this.fn_closeDialogAlert();
        this.$root.fn_gotoPermissionSettings();
    },

    fn_refresApp: function(){
        let self = this;
        console.log('Update Function Called');
        this.getData("ReopenApp", null, {
            onSuccess: function(response){
                self.dialog_update = false;
                console.log(response);
            },
            onError: function(error){
                console.log(error); 
                console.log('Test');
                self.$root.fn_showToast("exception");
            }
        });
    }
  },

  mounted(){
    this.$eventHub.$on('ShowAlert', alertObject => {
        console.log(alertObject);
        let showAlert = true;
        if(alertObject && alertObject.type && alertObject.type == 'notification'){
          this.alertDataObj.title = "Allow Notification Permission";
          this.alertDataObj.description = "Allow notification permission to receive alerts and notifications.";
          this.alertDataObj.okBtnText = "Allow";
          this.alertDataObj.cancelBtnText = "Deny"
          this.alertDataObj.hasThreeBtns = true;
          this.alertDataObj.thirdBtnText = "Don't ask again"
          this.alertDataObj.actionMethod = this.fn_goToSettings;
          this.alertDataObj.closeDialog = this.fn_closeDialogAlert;
          this.alertDataObj.thirdBtnAction = this.fn_doNotAskAgain;
          this.$root.permissionType = "notification";
          if(localStorage.getItem('donotaskfornotification')){
            showAlert = false;
          }
        }
        if(showAlert){
          window.js_globals.fn_onBackPressPrevious = window.js_globals.fn_onBackPress;
          window.js_globals.fn_onBackPress = this.fn_closeDialogAlert;
          this.dialog_alert = true;
        }
    });

    this.$eventHub.$on('PermissionDialog', () => {
        window.js_globals.fn_onBackPressPrevious = window.js_globals.fn_onBackPress;
        window.js_globals.fn_onBackPress = this.fn_closeDialogPermission;
        this.dialog_permission = true;
    });

    this.$eventHub.$on('updatefound', () => {
        window.js_globals.fn_onBackPressPrevious = window.js_globals.fn_onBackPress;
        window.js_globals.fn_onBackPress = this.fn_closeDialogUpdate;
        this.dialog_update = true;
    });
   
  }

//   components: {
//     // HelloWorld,
//     'homepage':home
//   },

//   data: () => ({
//     //
//   }),
};
</script>

<style>
/* Slide Right */
.slideRight-enter-from {
  opacity: 0;
  transform: translateX(100px)
}
.slideRight-enter-active,
.slideRight-leave-active {
  transition: all .15s ease-out
}
.slideRight-leave-to {
  opacity: 0;
  transform: translateX(100px)
}

/* Slide Left */
.slideLeft-enter-from {
  opacity: 0;
  transform: translateX(-100px)
}
.slideLeft-enter-active,
.slideLeft-leave-active {
  transition: all .15s ease-out
}
.slideLeft-leave-to {
  opacity: 0;
  transform: translateX(-100px)
}
    .v-badge{
        top:10px;
    }
    /* .v-ripple__container {
        display:none !important;
    } */
    .theme--light.v-list-item:hover::before,
    .theme--light.v-btn:hover::before,
    .theme--dark.v-btn:hover::before,
    .theme--light.v-tabs .v-tab--active:hover::before, 
    .theme--light.v-tabs .v-tab--active::before,
    .theme--light.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header:hover:before{
        opacity: 0 !important;
    }
    .hasBottomNav{
        padding-bottom: 64px;
    }

    .platinumclub .hasBottomNav{
        padding-bottom: 100px;
    }
    
    .v-application .v-btn--text.v-snack__btn,
    .v-application .yellow--text.text--darken-3{
        color: #FDD835!important;
        caret-color: #FDD835!important;
    }
    p{
        margin-bottom: 4px !important;
    }
    [full-height], .full-height{
        height:100%;
    }
    [grey-back], .grey-back{
        background: #f9f9f9;
    }
    [center-align], .center-align{
        text-align: center;
    }
    [right-align], .right-align{
        text-align: right;
    }
    [justify-center], .justify-center{
        justify-content: center;
    }
    [font-small], .font-small{
        font-size: small;
    }
    [light-opacity-color], .light-opacity-color{
        color: rgba(0,0,0,0.54);
    }
    [height100], .height100{
        height: 100% !important;
    }
    [capitalize], .capitalize{
        text-transform: capitalize !important;
    }
    [text-transform-none], .text-transform-none{
        text-transform: none !important;
    }
    [font-medium], .font-medium{
        font-weight: 500;
    }
    [font-heavy], .font-heavy{
        font-weight: 600;
    }
    [noborder-radius], .noborder-radius{
        border-radius: 0 !important;
    }
    [primarycolor], .primarycolor{
        background: #d64227;
    }
    [noshadow], .noshadow{
        box-shadow: none;
    }
    [center-align], .center-align{
        text-align: center;
    }
    [full-width], .full-width{
        width: 100% !important;
    }

    /* ======== backgrounds ============ */
    [light-blue], .light-blue{
        background: #90CAF9;
    }
    [light-pink], .light-pink{
        background: #F48FB1;
    }
    [light-purple], .light-purple{
        background: #CE93D8;
    }
    [light-green], .light-green{
        background: #C5E1A5;
    }
    /* ================= Toolbar Css ===============*/
    .v-toolbar{
        position:sticky !important;
        top:0;
        z-index:9;
    }
    .v-toolbar .v-badge{
        top:0px;
    }
    /* ================= End of Toolbar Css ===============*/

    /* ============= Home Page ============= */
    .v-bottom-navigation .v-btn{
        background: #ffffff !important;
        height: 100% !important;
    }
    .v-card__title{
        word-break:break-word !important; 
    }
    .v-card__text{
        color: rgba(0,0,0,0.87) !important;
    }
    .v-card__text p{
        color: rgba(0,0,0,0.87) !important;
    }
    .v-card__text img{
        max-width: 100%;
    }

    .v-dialog .v-card .v-window-item .v-image.v-responsive{
        height: 100% !important;
    }
    /* ============= End of Home Page ============= */

    /* PC Tile Formatting */

    .pctile_formatting{
        margin-right: -26px !important;
    }

    .pctile_formatting .card_tile{
        border-radius: 20px;
        text-align: center;
        padding-top: 16px !important;
        padding-bottom: 16px !important;
        margin-right: 26px !important;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }

    .pctile_formatting .card_tile .tile .tile-title{
        margin-left: 8px !important;
        margin-right: 8px !important;
        font-size: 0.913rem;
        font-weight: 600;
    }

    .top-tiles-grid.pctile_formatting .tile-image-wrapper{
        width: 130px;
        height: 130px;
    }

    .pctile_formatting .flex{
        width: 100%;
        min-height: 140px;
        margin-top: 26px !important;
    }

    .pctile_formatting .card_tile .tile-image-wrapper .v-image{
        margin: auto;
    }

    .form_dialog_text{
        padding: 0 17px 0px !important;
    }

    .timeselect_btn .v-radio{
        width: 45%;
        border: 1px solid black;
        padding: 8px;
        display: inline-block;
    }

    .timeselect_btn .v-radio.v-radio--is-disabled .v-input--selection-controls__input{
        display: none;
    }

    .timeselect_btn .v-radio.v-radio--is-disabled .v-label{
        left: 33px !important;
    }

    .dateselect_btn .v-radio{
        margin-right: 10px !important;
    }

    .platinumclub_custom_formatting .timeselect_btn .v-radio.v-item--active .v-icon{
        color: teal !important;
    }

    .platinumclub_custom_formatting .timeselect_btn .v-radio.v-item--active .v-label{
        color: teal !important;
    }

    .width_100{
        width: 100%;
    }

    .qrstepper .v-stepper__content{
        border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
    }

    .qrstepper .v-stepper__content .v-stepper__wrapper{
        height: auto !important;
    }

    .qrstepper .form_stepper .v-stepper__step{
        background-color: #f4f4f4;
        border-bottom: 1px #ddd solid;
    }

    .qrstepper .form_stepper.v-stepper .v-stepper__step .v-stepper__step__step{
        background-color: #3f51b5!important;
    }

    .qrstepper .form_stepper .v-stepper__step .v-stepper__label{
        font-size: 12pt;
        color: #000;
    }

    .qrstepper .fields_sections_header {
        font-size: 13pt;
        font-weight: 500;
        color: #000;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    /* ============= End of Home Page ============= */

    /* ============= Explore Page ============= */
    .top-tiles-grid .tile-image-wrapper{
        padding:15px 12px;
        /* background: #f9f9f9; */
        /* background: #AB47BC; */
        width: 68px;
        height: 68px;
        margin: auto;
        border-radius: 20px;
    }

    .top-tiles-grid .tile-image-wrapper .v-icon{
        font-size: 36px !important;
    }

    .platinumclub .top-tiles-grid .tile-image-wrapper{
        border:1px #395b83 solid;
    }

    .gcamericas .top-tiles-grid .tile-image-wrapper{
        border:1px #d64227 solid;
    }

    .gcemea .top-tiles-grid .tile-image-wrapper{
        border:1px #0093c9 solid;
    }

    .gcasia .top-tiles-grid .tile-image-wrapper,
    .demotrip .top-tiles-grid .tile-image-wrapper{
        border:1px #004987 solid;
    }

    .platinumclub .theme--light.v-bottom-navigation .v-btn:not(.v-btn--active){
        color: #ffffff !important;
    }

    .platinumclub .v-bottom-navigation .v-btn.v-btn--active{
        color: #00000099 !important;
    }

    .platinumclub .top-tiles-grid .tile-image-wrapper .v-icon{
        color:#395b83;
    }

    .gcamericas .top-tiles-grid .tile-image-wrapper .v-icon{
        color:#d64227;
    }

    .gcemea .top-tiles-grid .tile-image-wrapper .v-icon{
        color:#0093c9;
    }

    .gcasia .top-tiles-grid .tile-image-wrapper .v-icon,
    .demotrip .top-tiles-grid .tile-image-wrapper .v-icon{
        color:#004987;
    }

    .top-tiles-grid .tile-image-wrapper .v-image{
        margin:auto;
    }

    .top-tiles-grid .tile-title{
        font-size:0.813rem;
        font-weight:500;
        color: rgba(0,0,0,0.72);
    }

    .tile-section .tile-wrapper{
        padding: 8px;
    }
    .tile-section .tile-wrapper .v-image{
        border-radius: 5px;
    }
    .tile-section .tile-title{
        padding: 8px 0px 0px 0px;
        font-size: 0.875rem;
        font-weight: 500;
        color: rgba(0,0,0,0.72);
    }

    .slider-card-wrapper{
        overflow: auto !important;
        white-space: nowrap;
        margin:24px 16px 16px 16px;
        padding-bottom:24px;
    }

    .slider-card-wrapper .v-card{
        display:inline-block;
        border-radius: 12px !important;
        width:75%;
    }

    .slider-card-wrapper .v-card .v-card__actions{
        position: absolute;
        bottom:0;
    }

    .slider-card-wrapper .v-card .v-card__actions .v-icon{
        font-size:32px;
    }
    /* ============= End of Explore Page ============= */


    /* .v-toolbar__extension{
        justify-content: center;
        padding: 4px 0px;
    }
    .page-scrolled .v-toolbar__extension{
        background-color: #d64227;
        transition: background-color 0.2s;
    } */

    /* ============= schedule page css ============== */
    .schedule-page-wrapper{
        min-height:100%;
    }
    .v-slide-group__prev, .v-slide-group__next{
        display: none !important;
    }
    .v-tabs-bar{
        position: sticky !important;
        top: 0 !important;
        z-index: 1 !important;
    }
    
    .v-tab{
        max-height: 56px;
    }

    /* .gcamericas .v-tab{
        width:110px !important;
    }

    .platinumclub .v-tab{
        width:100px !important;
    } */

    .platinumclub .explore_page .more-tiles-wrapper .tile-section .flex .tile-wrapper{
        background-color: transparent;
    }

    .platinumclub .v-tab,
    .gcamericas .v-tab,
    .gcemea .v-tab,
    .gcasia .v-tab,
    .demotrip .v-tab{
        width:120px !important;
        letter-spacing: normal;
    }

    .platinumclub .v-tab--active,
    .platinumclub-dialog .v-tab--active{
        color: #5cbfbf !important;
    }

    .gcamericas .v-tab--active,
    .gcamericas-dialog .v-tab--active{
        color: #003765 !important;
    }

    .gcemea .v-tab--active,
    .gcemea-dialog .v-tab--active{
        color: #201650 !important;
    }

    .gcasia .v-tab--active,
    .gcasia-dialog .v-tab--active,
    .demotrip .v-tab--active,
    .demotrip-dialog .v-tab--active{
        color: #005474 !important;
    }
    
    .v-tab--active{
        background: #fff !important;
        /* color: #d64227 !important; */
        font-weight: 600;
    }

    .v-window-item .v-card{
        border-radius: 10px;
    }
    .v-window-item .v-card .title-wrapper{
        padding:8px 16px;
    }
    .v-window-item .v-card .title-wrapper .title-icon{
        display: inline-block;
        width: 36px;
        height: 36px;
        border-radius: 10px;
        padding: 6px 6px;
        margin-right: 12px;
    }
    .infopage .v-window-item .v-card .title-wrapper .title-icon{
        padding: 5px 6px !important;
    }
    .v-window-item .v-card .title-wrapper .title{
        display: inline;font-size: 0.875rem !important;
    }
    
    /* ============= schedule page css end ============== */

    /* ============== profile page css ================ */
    .logout-item{
        color:red !important;
        min-height:48px !important;
    }
    /* ============== end of profile page css ================ */

    /* ============ Info pages css ================ */
    .v-expansion-panel::before{
        box-shadow: none !important;
    }
    .v-expansion-panel-content__wrap{
        padding: 16px 16px 16px !important;
    }
    .theme--light.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header--active:hover::before, 
    .theme--light.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header--active::before{
        opacity: 0.05 !important;
    }
    .v-expansion-panel--active > .v-expansion-panel-header{
        min-height: 56px !important;
    }
    .v-expansion-panel-header{
        line-height: 1.5 !important;
    }
    .short-card-text{
        max-height:200px;
        overflow:hidden
    }
    /* ============ End of Info pages css ================ */

    /* ============ placeholder-wrapper =============== */
    .placeholder-wrapper{
        display: flex;height: calc(100vh - 56px);justify-content: center; align-items: center
    }
    .placeholder-wrapper .placeholder-icon{
        font-size:72px !important;opacity:0.6;
    }
    .placeholder-wrapper.content-page-placeholder{
        height: calc(100vh - 206px);
    }

    .placeholder-wrapper.pickyourslot_section{
        height: 100%;
    }

    /* =========== */
    .ql-indent-1{
        margin-left: 16px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: red !important;
        border-radius: 5px !important;
    }
    /* =========== */

    /* ====== Head SHot pages css ===== */

    .headshot_image_container{
        position: relative;
        /* width: 200px; */
    }

    .headshot_image_container .v-btn{
        position: absolute;
        right: 6px;
        top: 8px;
        background-color: white;
    }

    .headshot_image_container .section_image{
        margin-left: -8px !important;
    }

    .headshot-placeholder-wrapper{
        height: calc(100vh - 57px) !important;
    }

    /* ============ Media Css ================= */


    /*PC Footer Formatting*/

    .platinumclub .more-tiles-wrapper .tile-section .flex{
        z-index: 1;
    }

    .platinumclub .footer_watermark_image{
        position: fixed;
        bottom: 48px;
        width: 100%;
        z-index: 0;
    }

    .platinumclub .footer_watermark_image img{
       width: 100%;
    }

    .platinumclub .footer_wave_image{
        position: fixed;
        width: 100%;
        /* margin-bottom: 45px; */
        border: 0px !important;
        z-index: 2;
        bottom: 45px;
    }

    .platinumclub .footer_wave_image img{
        width: 100%;
    }

    .platinumclub .pcfooter_image{
        position: fixed;
        bottom: -8px;
        z-index: 0;
    }

    .platinumclub .pcfooter_image img{
       width: 100%;
    }

    .platinumclub .pcfooter_wave_image{
        position: fixed;
        z-index: 2;
        bottom: -7px;
    }

    .platinumclub .pcfooter_wave_image img{
        width: 100%;
    }

    .platinumclub .v-item-group.v-bottom-navigation{
        box-shadow: none !important;
    }

    /* .pcfooter_image{
        position: fixed;
        bottom: -8px;
        z-index: 0;
    } */

    .platinumclub .footer_override{
        z-index: 2;
        background-color: transparent;
    }

    .platinumclub .v-slide-group{
        z-index: 5 !important;
    }

    .transparentbg{
        background-color: transparent !important;
    }

    .zindex0{
        z-index: 0;
    }

    .zindex1{
        z-index: 1;
    }

    .zindex2{
        z-index: 2;
    }

    .custom-placeholder-size input::placeholder {
        font-size: 11.5pt;
    }

    .custom_heading{
      font-weight: 500;
      font-size: 15pt;
    }

    .gala_top_card{
        border-bottom: 20px solid #e8e8e8;
    }

    .include_guest_btn .v-label{
        color: white !important;
    }

    .include_guest_btn .v-input--switch__track{
        color: black !important;
    }

    /*END CSS*/

    /* Social Feed CSS*/

    /* .socialfeed_topsection .v-list-item{
        border-bottom: 6px solid #0000001f;
    } */

    .customdivider{
        border-width: thin 0px 10px 0px;
    }

    .posted_content_section{
        position: relative;
        z-index: 3;
        background-color: white;
    }

    .addcaptiontextbox{
        border-radius: 30px !important;
    }

    .addcaptiontextbox_custom{
        /* border: 1px solid #00000061; */
        padding: 16px;
        border-radius: 30px;
        color: #333333;
        padding-left: 0px;
    }

    .image_caption{
        font-size: 12pt;
    }

    .textdtatype .text_data{
        font-size: 16pt;
    }

    .commenttext_data{
        font-size: 12pt;
    }

    .addcomment_dialog{
        height: 100%;
    }

    .comment_text{
        white-space: normal !important;
        font-size: 12pt !important;
    }

    .align_top{
        align-self: normal !important;
    }

    .action_btn .btn_text{
        text-transform: none;
        font-size: 16px;
        color: #000000de;
        font-weight: normal;
    }

    .attendeelist_customformatting{
        min-height: auto !important;
    }

    @media (min-width: 0px) and (max-width: 300px) {
        .slider-card-wrapper .v-card{
            width:90%
        }
        /* .gcamericas .explore_page .v-toolbar .v-image__image{
            background-image:url('./assets/GCAmericas_TripBanners_Mobile_420x120.png') !important;
        } */
        /* .platinumclub .explore_page .v-toolbar .v-image__image{
            background-image:url('./assets/PlatinumClub_TripBanners_Mobile_640x80.png') !important;
        } */
    }

    @media (min-width: 301px) and (max-width: 600px) {
        .slider-card-wrapper .v-card{
            width:75%
        }
        /* .gcamericas .explore_page .v-toolbar .v-image__image{
            background-image:url('./assets/GCAmericas_TripBanners_Mobile_420x120.png') !important;
        } */
        /* .platinumclub .explore_page .v-toolbar .v-image__image{
            background-image:url('./assets/PlatinumClub_TripBanners_Mobile_640x80.png') !important;
        } */
    }

    @media (min-width: 601px) {
        .slider-card-wrapper .v-card{
            width:60%
        }
        /* .gcamericas .explore_page .v-toolbar .v-image__image{
            background-image:url('./assets/TripBanners_Mobile_GC_1080x80_1.png') !important;
        } */
        /* .platinumclub .explore_page .v-toolbar .v-image__image{
            background-image:url('./assets/TripBanners_Mobile_PC_1080x80.png') !important;
        } */
        .more-tiles-wrapper .v-card .v-image{
            height: 180px !important;
        }
    }

    /* ============ End of Media Css ================= */
</style>