<template>
    <div class="lighten-3" style="min-height:100vh">
        <!-- <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>Gala Event Seating</v-toolbar-title>
        </v-toolbar> -->

        <!-- <v-toolbar :color="$root.themeColor" flat extended extension-height="62"></v-toolbar> -->
        <v-toolbar :color="$root.themeColor" flat>
            <v-btn icon @click="$router.go(-1)">
                <v-icon color="white">{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title class="white--text">Gala Event</v-toolbar-title>
            <!-- <v-text-field dense clearable type="search" flat solo hide-details placeholder="Search by Table number or Name" v-model="searchValue" slot="extension"></v-text-field> -->
        </v-toolbar>

        <!-- <div class="center-align pa-8">
            <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
        </div> -->

        <!-- <div v-if="$root.Envirenment == 3"></div> -->
        <div v-if="$root.Envirenment == 3">
            <v-card flat class="zindex2 transparentbg">
                <v-card-text><p>Check back for updates!</p></v-card-text>
            </v-card>
        </div>

        <template v-if="error">
            <div style="text-align:center;" class="pa-4">
                <p>Something went wrong. Please try again.</p>
                <br>
                <v-btn outlined @click="fn_getGalaSeatingData"><v-icon left>refresh</v-icon> Retry</v-btn>
            </div>
        </template>
        
        <template v-if="loading">
            <div class="pa-8">
                <v-img width="120" style="margin:auto" src="../../../assets/giphy.gif"></v-img>
            </div>
        </template>

        <div class="gala_top_card" v-if="!loading &&  groupPhotoData && groupPhotoData.AttendeeGroupPhotoList && groupPhotoData.AttendeeGroupPhotoList.length>0 && $root.tripOnLoadData.AttendeeInfo && $root.tripOnLoadData.AttendeeInfo.AttendeeType == 1">
            <v-card class="mb-2" flat>
                <div class="pa-4">
                    <p class="mb-0">Your assignments for the Gala Event on Tuesday, October 8 are listed below.</p>
                </div>
                <div class="custom_heading pl-4">Group Photos</div>
                <div class="pa-4 pb-2">
                    <p class="mb-0">Group photos will begin promptly at 5:45 PM.</p>
                </div>
                
                <v-list class="pt-0 pb-0">
                    <template v-for="(group, groupIndex) in groupPhotoData.AttendeeGroupPhotoList">
                        <v-list-item :key="groupIndex">
                            <v-list-item-content>
                                <p><b>Group #{{ group.GroupNo }}: </b>{{ group.GroupName }}</p>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider class="mx-4" v-if="groupPhotoData.AttendeeGroupPhotoList.length - 1 != groupIndex" :key="'divider'+groupIndex"></v-divider>
                    </template>
                </v-list>
                <v-divider class="mx-4"></v-divider>
                <div class="pl-4 pb-4 pt-4">
                    <a v-if="$root.tripOnLoadData.AttendeeInfo && $root.tripOnLoadData.AttendeeInfo.RegistrationFormType == 1" type="article" class="pr-2" href="/assets/platinumclub/GroupPhoto_Winner_12.pdf" target="_blank">Group Photo PDF</a>
                    <a v-else type="article" class="pr-2" href="/assets/platinumclub/GroupPhoto_Exec Host_12.pdf" target="_blank">Group Photo PDF</a>
                </div>
            </v-card>
        </div>

        <div style="padding-bottom: 30px;" v-if="!loading && galaSeatingData">
            <v-card class="mb-4" flat v-if="galaSeatingData.MyTable">
                <!-- <v-card-title primary-title>My Table #{{galaSeatingData.MyTable}}</v-card-title> -->
                <!-- <v-divider class="mx-4"></v-divider> -->
                <v-list>
                    <v-list-item @click="fn_jumpToTable(galaSeatingData.MyTable)">
                        <v-list-item-avatar class="light-pink">
                            <template v-if="galaSeatingData.PhotoUrl"><v-img alt="winnerimage" :src="galaSeatingData.PhotoUrl" lazy-src="../../../assets/person.svg"></v-img></template>
                            <template v-else><v-icon dark>person</v-icon></template>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{galaSeatingData.Name}}</v-list-item-title>
                            <v-list-item-subtitle v-if="galaSeatingData.GuestName">&amp; {{galaSeatingData.GuestName}}</v-list-item-subtitle>
                            <v-list-item-title>
                                Table <strong>#{{galaSeatingData.MyTable}}</strong>
                                <v-chip class="ml-2" x-small label color="green" text-color="white">
                                    <v-icon x-small>arrow_downward</v-icon> &nbsp; View Table
                                </v-chip>
                            </v-list-item-title>
                        </v-list-item-content>
                        <!-- <v-list-item-icon>
                            <v-icon>arrow_downward</v-icon>
                        </v-list-item-icon> -->
                    </v-list-item>
                </v-list>
            </v-card>
            <!-- <v-text-field dense clearable type="search" flat solo hide-details placeholder="Search by Table number or Name" v-model="searchValue" slot="extension"></v-text-field> -->
            <div>
                <div class="custom_heading pt-4 pl-4">Gala Seating Assignment</div>
                <div class="pa-4 pb-2">
                    <p class="mb-0">You will find your assigned seat when you get to your table. Please refrain from moving your seat as your entree is served based on your assigned seat number.</p>
                </div>
                
                <v-text-field v-if="galaSeatingData" class="pa-4 custom-placeholder-size" outlined dense clearable type="search" flat solo hide-details placeholder="Search by Table number or Name" v-model="searchValue" slot="extension"></v-text-field>
                <v-card flat class="mb-4 zindex2" v-for="(item, index) in filteredTablesData" :key="index" :id="'table_number_'+item.TableNo">
                    <v-card-title primary-title style="position:sticky;top:56px;background-color:#ffffff;z-index:1;">Table #{{item.TableNo}}</v-card-title>
                    <v-divider class="mx-4" v-if="item.Hosts && item.Hosts.length"></v-divider>
                    <v-list v-if="item.Hosts && item.Hosts.length">
                        <template v-for="(host, hostIndex) in item.Hosts">
                            <v-list-item :key="hostIndex" @click="fn_tableInfo(item.TableNo)">
                                <v-list-item-avatar :class="colorClasses[(hostIndex % colorClasses.length)]" style="align-self: baseline;">
                                    <template v-if="host.PhotoUrl"><v-img alt="winnerimage" :src="host.PhotoUrl" lazy-src="../../../assets/person.svg"></v-img></template>
                                    <template v-else><v-icon dark>person</v-icon></template>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title>{{host.AttendeeName}}</v-list-item-title>
                                    <v-list-item-subtitle>Executive Host</v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="host.GuestName">&amp; {{host.GuestName}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider inset v-if="item.Hosts.length - 1 != hostIndex" :key="'divider'+hostIndex"></v-divider>
                        </template>
                    </v-list>
                    
                    <v-list class="pa-0" two-line>

                        <!-- <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Guests:</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->
                        <v-subheader><strong>Guests:</strong></v-subheader>
                        <v-divider class="mx-4"></v-divider>

                        <template v-for="(participant, participantIndex) in item.Participants">
                            
                            <v-list-item :key="participantIndex" @click="fn_tableInfo(item.TableNo)">
                                <v-list-item-avatar :class="colorClasses[(participantIndex % colorClasses.length)]" style="align-self: baseline;">
                                    <template v-if="participant.PhotoUrl"><v-img alt="winnerimage" :src="participant.PhotoUrl" lazy-src="../../../assets/person.svg"></v-img></template>
                                    <template v-else><v-icon dark>person</v-icon></template>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title v-text="participant.AttendeeName"></v-list-item-title>
                                    <v-list-item-subtitle class="pb-1" v-if="participant.GuestName">&amp; {{participant.GuestName}}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="participant.JobTitle">{{participant.JobTitle}}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="participant.AreaName">{{participant.AreaName}}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="participant.Subsidiary && participant.AreaName != participant.Subsidiary">{{participant.Subsidiary}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider inset v-if="item.Participants.length - 1 != participantIndex" :key="'divider'+participantIndex"></v-divider>
                            
                        </template>

                        
                    </v-list>
                </v-card>
            </div>
            
        </div>

        <div class="pcfooter_image">
            <img src="../assets/2024/PlatinumClub_SideWaveBG_1120x920.png">
        </div>
        <div class="pcfooter_wave_image">
            <img src="../assets/2024/PlatinumClub_BottomWave_1120x112.png">
        </div>

        <!-- <div class="pcfooter_image">
            <img v-show="0" src="../assets/PlatinumClub2023_FooterImage_420x200.png">
        </div> -->

        <v-dialog ref="ref_tableInfoDialog" v-model="dialogTableInfo" scrollable transition="dialog-bottom-transition" fullscreen persistent>
            <v-card>
                <v-card-text class="scrollable-content pa-0">
                    <!-- <table-info ref="ref_tableInfo" :closetableInfo="fn_closetableInfo"></table-info>     -->
                    <div style="min-height:100vh" class="lighten-3">
                        <v-toolbar :color="$root.themeColor" flat dark>
                            <v-btn icon @click="dialogTableInfo=false">
                                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
                            </v-btn>
                            <v-toolbar-title v-if="tableInfo">Table #{{tableInfo.TableNo}}</v-toolbar-title>
                        </v-toolbar>

                        <div>

                            <template v-if="errorInfo">
                                <div style="text-align:center;" class="pa-4">
                                    <p>Something went wrong. Please try again.</p>
                                    <br>
                                    <v-btn outlined @click="fn_tableInfo(currentTableNo)"><v-icon left>refresh</v-icon> Retry</v-btn>
                                </div>
                            </template>

                            <template v-if="loadingInfo">
                                <div class="pa-8">
                                    <v-img width="120" style="margin:auto" src="../../../assets/giphy.gif"></v-img>
                                </div>
                            </template>

                            <template v-else>

                                <v-list v-if="tableInfo && tableInfo.Hosts && tableInfo.Hosts.length">
                                    <template v-for="(host, hostIndex) in tableInfo.Hosts">
                                        <v-list-item :key="hostIndex" class="zindex2" :id="host.AttendeeID">
                                            <v-list-item-avatar :class="colorClasses[(hostIndex % colorClasses.length)]">
                                                <template v-if="host.PhotoUrl"><v-img alt="winnerimage" :src="host.PhotoUrl" lazy-src="../../../assets/person.svg"></v-img></template>
                                                <template v-else><v-icon dark>person</v-icon></template>
                                            </v-list-item-avatar>

                                            <v-list-item-content>
                                                <v-list-item-title>{{host.AttendeeName}}</v-list-item-title>
                                                <v-list-item-subtitle>Executive Host</v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="host.GuestName">&amp; {{host.GuestName}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-list>

                                <v-list class="pt-0" two-line v-if="tableInfo && tableInfo.Participants && tableInfo.Participants.length">
                                    <v-subheader><strong>Guests:</strong></v-subheader>
                                    <v-divider class="mx-4"></v-divider>
                                    <template v-for="(participant, participantIndex) in tableInfo.Participants">
                                        <v-list-item :key="participantIndex" class="zindex2" :id="participant.AttendeeID">
                                            <v-list-item-avatar :class="colorClasses[(participantIndex % colorClasses.length)]" style="align-self: baseline;">
                                                <template v-if="participant.PhotoUrl"><v-img alt="winnerimage" :src="participant.PhotoUrl" lazy-src="../../../assets/person.svg"></v-img></template>
                                                <template v-else><v-icon dark>person</v-icon></template>
                                            </v-list-item-avatar>

                                            <v-list-item-content>
                                                <v-list-item-title v-text="participant.AttendeeName"></v-list-item-title>
                                                <v-list-item-subtitle class="pb-1" v-if="participant.GuestName">&amp; {{participant.GuestName}}</v-list-item-subtitle>

                                                <v-list-item-subtitle v-if="participant.JobTitle">{{participant.JobTitle}}</v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="participant.AreaName">{{participant.AreaName}}</v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="participant.Subsidiary && participant.AreaName != participant.Subsidiary">{{participant.Subsidiary}}</v-list-item-subtitle>
                                                <v-list-item-subtitle class="pt-2" v-if="participant.AwardCategory"><strong>{{participant.AwardCategory}}</strong></v-list-item-subtitle>
                                                <v-list-item-subtitle style="white-space:normal" v-if="participant.Achievements">{{participant.Achievements}}</v-list-item-subtitle>
                                                
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-divider inset v-if="tableInfo.Participants.length - 1 != participantIndex" :key="'divider'+participantIndex"></v-divider>
                                        
                                    </template>
                                </v-list>

                            </template>
                            
                            
                        </div>
                        
                    </div>
                    <div class="pcfooter_image">
                            <img v-show="0" src="../assets/PlatinumClub2023_FooterImage_420x200.png">
                        </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios';
// import tableInfoComp from './display';

export default{
    data(){
        return{
            galaSeatingData:null,
            loading:false,
            error:false,
            colorClasses:['light-blue', 'light-pink', 'light-purple', 'light-green'],
            searchValue:null,
            dialogTableInfo:false,
            tableInfo:null,
            loadingInfo:false,
            errorInfo:false,
            currentTableNo:null,
            groupPhotoData: null
        }
    },
    // components:{
    //     'table-info':tableInfoComp
    // },
    methods:{
        fn_tableInfo: function(tableNo){
            this.currentTableNo = tableNo;
            this.dialogTableInfo = true;
            this.loadingInfo = true;
            this.errorInfo = false;
            this.tableInfo = null;
            // this.$refs['ref_tableInfo'].fn_getTableInfo(tableNo);
            this.fn_getTableInfo(tableNo);
        },

        fn_getTableInfo: function(tableNo){
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loadingInfo = false;
                    this.errorInfo = true;
                    this.$root.fn_showToast('exception');
                }
                else{
                    var headersObj = {
                    headers: {
                            'Authorization': 'bearer '+token,
                        }
                    };

                    axios.get(this.$root.baseUrl + "/api/mobileapp/galaseating/attendee/"+tableNo, headersObj, {timeout:30000})
                    .then(response => {
                        console.log(response);
                        this.loadingInfo = false;
                        this.tableInfo = response.data;

                        setTimeout(() => {
                            document.getElementsByClassName('scrollable-content')[0].scrollTop = 0;
                        }, 300);

                    }).catch(e => {
                        console.log(e);
                        this.loadingInfo = false;
                        this.errorInfo = true;
                        let toastMsg = e && e.message ? e.message : 'exception';
                        this.$root.fn_showToast(toastMsg);
                    })
                }
            })
        },

        fn_closetableInfo: function(){
            this.dialogTableInfo = false;
        },

        fn_jumpToTable: function(tableNo){
            // document.getElementById("table_number_"+tableNo).scrollIntoView({top:100, behavior: 'smooth'});
            var element = document.getElementById("table_number_"+tableNo);
            var headerOffset = 118;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        },

        fn_abbreviatedName: function(str){
            let words = str.trim().split(' ');

            if(words.length > 1){
                return words[0][0].toUpperCase() + words[1][0].toUpperCase();    
            }
            else{
                return words[0][0].toUpperCase();
            }
        },

        fn_getGalaSeatingData: function(){
            this.loading = true;
            this.error = false;
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                this.loading = false;
                this.error = true;
                this.$root.fn_showToast('exception');
                }
                else{
                    var headersObj = {
                    headers: {
                            'Authorization': 'bearer '+token,
                        }
                    };

                    axios.get(this.$root.baseUrl + "/api/mobileapp/galaseating/attendee/list", headersObj, {timeout:30000})
                    .then(response => {
                        console.log(response);
                        this.galaSeatingData = response.data;
                        if(this.$root.tripOnLoadData.AttendeeInfo && this. $root.tripOnLoadData.AttendeeInfo.AttendeeType == 1){
                            this.fn_getGroupPhotoData();
                        }
                        else{
                            this.loading = false;
                        }
                        this.fn_photoAuth();
                        this.fn_photoAuthThumbnail();
                    }).catch(e => {
                        console.log(e);
                        this.loading = false;
                        this.error = true;
                        let toastMsg = e && e.message ? e.message : 'exception';
                        this.$root.fn_showToast(toastMsg);
                    })
                }
            })
        },

        fn_getGroupPhotoData: function(){
            this.error = false;
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                this.loading = false;
                this.error = true;
                this.$root.fn_showToast('exception');
                }
                else{
                    var headersObj = {
                    headers: {
                            'Authorization': 'bearer '+token,
                        }
                    };

                    axios.get(this.$root.baseUrl + "/api/mobileapp/attendee/groupphoto/list", headersObj, {timeout:30000})
                    .then(response => {
                        console.log(response);
                        this.loading = false;
                        this.groupPhotoData = response.data;
                    }).catch(e => {
                        console.log(e);
                        this.loading = false;
                        this.error = true;
                        let toastMsg = e && e.message ? e.message : 'exception';
                        this.$root.fn_showToast(toastMsg);
                    })
                }
            })
        },

        fn_closeDialog: function(){
            if(this.dialogTableInfo){
                this.dialogTableInfo = false;
            }
            else{
                window.js_globals.fn_onBackPress = this.$root.fn_goBack;
                this.$root.fn_goBack();
            }
        },

        fn_photoAuth: function() {

            this.loading = true;
            this.error = false;
            let self = this;

            if (self.galaSeatingData.PhotoUrl && !self.galaSeatingData.PhotoUrl.startsWith('data:image/')){
                this.$root.fn_getToken(token=>{
                    if(token == 'error'){
                        this.loading = false;
                        this.error = true;
                        this.$root.fn_showToast('exception');
                    }
                    else{
                        var headersObj = {
                            headers: { 
                                'Authorization': 'bearer '+token,
                            }
                        };

                        var getUrl = self.$root.baseUrl + self.galaSeatingData.PhotoUrl;
                        axios.get(getUrl, headersObj, {timeout:30000})
                        .then(response => {
                            self.galaSeatingData.PhotoUrl = 'data:image/png;base64,' + response.data;
                            console.log(self.galaSeatingData.PhotoUrl);
                            this.loading = false;
                            self.$forceUpdate();
                        }).catch(e => {
                            this.loading = false;
                            this.error = true;
                            let toastMsg = e && e.message ? e.message : 'exception';
                            this.$root.fn_showToast(toastMsg);
                            console.log(e);
                        })
                    }
                })

            }
        },

        async fn_photoAuthThumbnail() {
            let self = this;

            async function processPhotoUrls(photoUrls) {
                for (let i = 0; i < photoUrls.length; i++) {
                if (photoUrls[i].PhotoUrl && !photoUrls[i].PhotoUrl.startsWith('data:image/')) {
                    try {
                    const token = await new Promise((resolve, reject) => {
                        self.$root.fn_getToken((token) => {
                        if (token === 'error') {
                            reject('Token error');
                        } else {
                            resolve(token);
                        }
                        });
                    });

                    console.log(token + "Token Called");

                    const headersObj = {
                        headers: {
                        'Authorization': 'Bearer ' + token,
                        }
                    };

                    console.log(self.$root.baseUrl);
                    const response = await axios.get(self.$root.baseUrl + photoUrls[i].PhotoUrl, headersObj, { timeout: 30000 });

                    // Update the PhotoUrl with the retrieved data
                    console.log(response.data);
                    self.$set(photoUrls[i], 'PhotoUrl', 'data:image/png;base64,' + response.data);

                    console.log(photoUrls[i].PhotoUrl);
                    } catch (e) {
                    self.error = true;
                    console.log(e);
                    let toastMsg = e && e.message ? e.message : 'exception';
                    self.$root.fn_showToast(toastMsg);
                    break; // Stop processing on error
                    }
                }
                }
            }

            if (this.galaSeatingData && this.galaSeatingData.Tables) {
                for (let j = 0; j < this.galaSeatingData.Tables.length; j++) {
                    const table = this.galaSeatingData.Tables[j];
                    await processPhotoUrls(table.Hosts);
                    await processPhotoUrls(table.Participants);
                }
            }

            this.loading = false;
            this.error = false;
        },

        async fn_photoAuthtableInfo() {
            let self = this;

            async function processPhotoUrls(photoUrls) {
                for (let i = 0; i < photoUrls.length; i++) {
                if (photoUrls[i].PhotoUrl && !photoUrls[i].PhotoUrl.startsWith('data:image/')) {
                    try {
                    const token = await new Promise((resolve, reject) => {
                        self.$root.fn_getToken((token) => {
                        if (token === 'error') {
                            reject('Token error');
                        } else {
                            resolve(token);
                        }
                        });
                    });

                    console.log(token + "Token Called");

                    const headersObj = {
                        headers: {
                        'Authorization': 'Bearer ' + token,
                        }
                    };

                    console.log(self.$root.baseUrl);
                    const response = await axios.get(self.$root.baseUrl + photoUrls[i].PhotoUrl, headersObj, { timeout: 30000 });

                    // Update the PhotoUrl with the retrieved data
                    console.log(response.data);
                    self.$set(photoUrls[i], 'PhotoUrl', 'data:image/png;base64,' + response.data);

                    console.log(photoUrls[i].PhotoUrl);
                    } catch (e) {
                    self.error = true;
                    console.log(e);
                    let toastMsg = e && e.message ? e.message : 'exception';
                    self.$root.fn_showToast(toastMsg);
                    break; // Stop processing on error
                    }
                }
                }
            }

            if (this.tableInfo ) {
                if(this.tableInfo.Hosts)
                    await processPhotoUrls(this.tableInfo.Hosts);
                if(this.MyTable.tableInfo)
                    await processPhotoUrls(this.tableInfo.Participants);
            }

            this.loading = false;
            this.error = false;
        }

    },
    mounted() {
        window.js_globals.fn_onBackPress = this.fn_closeDialog;
        this.$root.fn_postClickMetrics('Explore Tiles', 'Gala Event', null);
        // this.fn_getGalaSeatingData(); 
        if (this.$root.Envirenment != 3) {
            this.fn_getGalaSeatingData();  
        }
    },
    computed:{
        filteredTablesData() {
            // var TotalCount = 0;
            var FilteredTables = [];
            var ismatch = false;
            for (let i = 0; i < this.galaSeatingData.Tables.length; i++) {
                var group = this.galaSeatingData.Tables[i];
                var newgroup = { TableNo: group.TableNo, Participants: [], Hosts: [] };
                for (let j = 0; j < group.Hosts.length; j++) {
                    ismatch = true;
                    if (ismatch && this.searchValue != null && this.searchValue != '') {
                        if (!isNaN(this.searchValue) && (group.TableNo == null || group.TableNo.toString() != this.searchValue)) {
                            ismatch = false;
                        }
                        if (ismatch
                            && isNaN(this.searchValue)
                            && !group.Hosts[j].AttendeeName.toLowerCase().match(this.searchValue.toLowerCase())
                            && !group.Hosts[j].Alias.toLowerCase().match(this.searchValue.toLowerCase())
                        ) {
                            ismatch = false
                        }
                    }
                    if (ismatch) {
                        newgroup.Hosts.push(group.Hosts[j]);
                    }
                }
                for (let j = 0; j < group.Participants.length; j++) {
                    ismatch = true;
                    if (ismatch && this.searchValue != null && this.searchValue != '') {
                        if (!isNaN(this.searchValue) && (group.TableNo == null || group.TableNo.toString() != this.searchValue)) {
                            ismatch = false;
                        }
                        if (ismatch
                            && isNaN(this.searchValue)
                            && !group.Participants[j].AttendeeName.toLowerCase().match(this.searchValue.toLowerCase())
                            && !group.Participants[j].Alias.toLowerCase().match(this.searchValue.toLowerCase())
                        ) {
                            ismatch = false
                        }
                    }
                    if (ismatch) {
                        newgroup.Participants.push(group.Participants[j]);
                    }
                }
                if (newgroup.Hosts.length > 0 || newgroup.Participants.length > 0) {
                    FilteredTables.push(newgroup);
                    // TotalCount += newgroup.Hosts.length + newgroup.Participants.length;
                }
            }
            /*return this.ListData.filter(item => {
                if (item.Name != null)
                    return item.Name.toLowerCase().match(this.searchValue.toLowerCase());
            });*/
            // this.TotalCount = TotalCount;
            return FilteredTables;
        }
    }
    // beforeRouteLeave(to, from, next) {
    //     console.log(to, from);
    //     if(this.dialogTableInfo){
    //         this.dialogTableInfo = false;
    //     }
    //     else{
    //         next(true);
    //     }
    // }
    // computed:{
    //     filteredTablesData: function(){
    //         if(this.galaSeatingData && this.galaSeatingData.Tables && this.galaSeatingData.Tables.length){
    //             return this.galaSeatingData.Tables.filter(table=>{
    //                 return table.Hosts.filter(host=>{host.AttendeeName.match(this.searchValue)}) || table.Participants.filter(participant=>{participant.AttendeeName.match(this.searchValue)})
    //             })
    //         }
    //         else{
    //             return [];
    //         }
    //     }
    // },
}
</script>