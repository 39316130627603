import Vue from 'vue'
import VueRouter from 'vue-router'

import token from '../components/token'

// import test from '../components/HelloWorld.vue'
// import chattestpage from '../components/testpage.vue'
import sendotp from '../components/login/sendotp'
import verifyotp from '../components/login/verifyotp'
import verifymobileotp from '../components/login/verifymobileotp'
import verifydob from '../components/login/verifydob'

import trips from '../components/trips'

// platinum club
import homePagePC from '../components/platinumclub/home'
// import programInclustionsPC from '../components/platinumclub/info_pages/program_inclusions'
import buyinInformationPC from '../components/platinumclub/info_pages/buyin_information'
import resortPC from '../components/platinumclub/info_pages/resort'
import activitiesPC from '../components/platinumclub/info_pages/activities'
import allActivitiesPC from '../components/platinumclub/info_pages/all_activities'
import restaurantReservationPC from '../components/platinumclub/info_pages/restaurant_reservation'
// import travelPC from '../components/platinumclub/info_pages/travel'
// import faqPC from '../components/platinumclub/info_pages/faq'
// import emergencyPC from '../components/platinumclub/info_pages/emergency'
// import chatpagePC from '../components/platinumclub/chat'
import profilepagePC from '../components/platinumclub/winner_attendee_profile'
import qrcodePC from '../components/platinumclub/gifts/qrcode'
// import documentsPC from '../components/platinumclub/documents'
// import mapsPC from '../components/platinumclub/maps'
// import infoPC from '../components/platinumclub/info'
import giftsPC from '../components/platinumclub/gifts/items'
import photoAlbumPC from '../components/platinumclub/photo_album/album'
import photosPC from '../components/platinumclub/photo_album/photos'
import headshotPC from '../components/platinumclub/info_pages/headshot'

import infoboothPC from '../components/platinumclub/info_pages/infobooth'
import eveningeventsPC from '../components/platinumclub/info_pages/eveningevents'
import safetyandsecurityPC from '../components/platinumclub/info_pages/safetyandsecurity'
import departurePC from '../components/platinumclub/info_pages/departure'

import galaSeatingPC from '../components/platinumclub/gala_seating/items'
import guestQRCode_platinumclub from '../components/platinumclub/info_pages/guestqrcode'
import executiveAttendence from '../components/platinumclub/info_pages/executiveattendance'
import donate from '../components/platinumclub/info_pages/donate'
import yourConfirmations from '../components/platinumclub/info_pages/yourconfirmation'
import socialfeedplatinumclub from '../components/platinumclub/socialfeed/socialfeed'
import keylocationsplatinumclub from '../components/platinumclub/info_pages/keylocations'
import survey from '../components/platinumclub/info_pages/survey'
import pcshuttle from '../components/platinumclub/info_pages/shuttle'

// Pinnacle
import homePage_pinnacle from '../components/pinnacle/home'
import resort_pinnacle from '../components/pinnacle/info_pages/resort'
import oncampus_pinnacle from '../components/pinnacle/info_pages/oncampus'
import herotiledetails_pinnacle from '../components/pinnacle/info_pages/herotile_details'
// import chat from '../components/pinnacle/chat'

// EMEA Gold Club
import homePage_emea from '../components/gcemea/home'
// import programInclustions_emea from '../components/gcemea/info_pages/program_inclusions'
import buyinInformation_emea from '../components/gcemea/info_pages/buyin_information'
import resort_emea from '../components/gcemea/info_pages/resort'
import activities_emea from '../components/gcemea/info_pages/activities'
import allActivities_emea from '../components/gcemea/info_pages/all_activities'
import restaurantReservation_emea from '../components/gcemea/info_pages/restaurant_reservation'
// import travel_emea from '../components/gcemea/info_pages/travel'
// import faq_emea from '../components/gcemea/info_pages/faq'
// import emergency_emea from '../components/gcemea/info_pages/emergency'
// import chatpage_emea from '../components/gcemea/chat'
import profilepage_emea from '../components/gcemea/winner_attendee_profile'
import qrcode_emea from '../components/gcemea/gifts/qrcode'
// import documents_emea from '../components/gcemea/documents'
// import maps_emea from '../components/gcemea/maps'
// import info_emea from '../components/gcemea/info'
import gifts_emea from '../components/gcemea/gifts/items'
import photoAlbum_emea from '../components/gcemea/photo_album/album'
import photos_emea from '../components/gcemea/photo_album/photos'

import infobooth_emea from '../components/gcemea/info_pages/infobooth'
import eveningevents_emea from '../components/gcemea/info_pages/eveningevents'
import safetyandsecurity_emea from '../components/gcemea/info_pages/safetyandsecurity'
import departure_emea from '../components/gcemea/info_pages/departure'
import resortCard_emea from '../components/gcemea/info_pages/sub_components/resort_card'
import guestQRCode_emea from '../components/gcemea/info_pages/guestqrcode'
import headshotEmea from '../components/gcemea/info_pages/headshot'
import keylocationsemea from '../components/gcemea/info_pages/keylocations'
import socialfeedemea from '../components/gcemea/socialfeed/socialfeed'
import shuttleschedule from '../components/gcemea/info_pages/shuttle'


// Americas Gold Club
import homePage_americas from '../components/gcamericas/home'
// import programInclustions_americas from '../components/gcamericas/info_pages/program_inclusions'
import buyinInformation_americas from '../components/gcamericas/info_pages/buyin_information'
import resort_americas from '../components/gcamericas/info_pages/resort'
import activities_americas from '../components/gcamericas/info_pages/activities'
import allActivities_americas from '../components/gcamericas/info_pages/all_activities'
import restaurantReservation_americas from '../components/gcamericas/info_pages/restaurant_reservation'
// import travel_americas from '../components/gcamericas/info_pages/travel'
// import faq_americas from '../components/gcamericas/info_pages/faq'
// import emergency_americas from '../components/gcamericas/info_pages/emergency'
// import chatpage_americas from '../components/gcamericas/chat'
import profilepage_americas from '../components/gcamericas/winner_attendee_profile'
import qrcode_americas from '../components/gcamericas/gifts/qrcode'
// import documents_americas from '../components/gcamericas/documents'
// import maps_americas from '../components/gcamericas/maps'
// import info_americas from '../components/gcamericas/info'
import gifts_americas from '../components/gcamericas/gifts/items'
import photoAlbum_americas from '../components/gcamericas/photo_album/album'
import photos_americas from '../components/gcamericas/photo_album/photos'
import guestQRCode_americas from '../components/gcamericas/info_pages/guestqrcode'

import infobooth_americas from '../components/gcamericas/info_pages/infobooth'
import eveningevents_americas from '../components/gcamericas/info_pages/eveningevents'
import safetyandsecurity_americas from '../components/gcamericas/info_pages/safetyandsecurity'
import departure_americas from '../components/gcasia/info_pages/departure'

import herotiledetails_americas from '../components/gcamericas/info_pages/herotile_details'
import headshotAmericas from '../components/gcamericas/info_pages/headshot'
import keylocationsamericas from '../components/gcamericas/info_pages/keylocations'
import socialfeedamericas from '../components/gcamericas/socialfeed/socialfeed'
import giveback from '../components/gcamericas/info_pages/giveback'

// Asia Gold Club
import homePage_asia from '../components/gcasia/home'
// import programInclustions_asia from '../components/gcasia/info_pages/program_inclusions'
import buyinInformation_asia from '../components/gcasia/info_pages/buyin_information'
import resort_asia from '../components/gcasia/info_pages/resort'
import activities_asia from '../components/gcasia/info_pages/activities'
import allActivities_asia from '../components/gcasia/info_pages/all_activities'
import restaurantReservation_asia from '../components/gcasia/info_pages/restaurant_reservation'
// import travel_asia from '../components/gcasia/info_pages/travel'
// import faq_asia from '../components/gcasia/info_pages/faq'
// import emergency_asia from '../components/gcasia/info_pages/emergency'
// import chatpage_asia from '../components/gcasia/chat'
import profilepage_asia from '../components/gcasia/winner_attendee_profile'
import qrcode_asia from '../components/gcasia/gifts/qrcode'
// import documents_asia from '../components/gcasia/documents'
// import maps_asia from '../components/gcasia/maps'
// import info_asia from '../components/gcasia/info'
import gifts_asia from '../components/gcasia/gifts/items'
import photoAlbum_asia from '../components/gcasia/photo_album/album'
import photos_asia from '../components/gcasia/photo_album/photos'

import infobooth_asia from '../components/gcasia/info_pages/infobooth'
import eveningevents_asia from '../components/gcasia/info_pages/eveningevents'
import safetyandsecurity_asia from '../components/gcasia/info_pages/safetyandsecurity'
import departure_asia from '../components/gcasia/info_pages/departure'

import herotiledetails_asia from '../components/gcasia/info_pages/herotile_details'
import guestQRCode_asia from '../components/gcasia/info_pages/guestqrcode'
import headshotAsia from '../components/gcasia/info_pages/headshot'
import shuttle from '../components/gcasia/info_pages/shuttle'
import keylocationsasia from '../components/gcasia/info_pages/keylocations'
import socialfeedasia from '../components/gcasia/socialfeed/socialfeed'

// Demo Trip
import homePage_demotrip from '../components/demotrip/home'
// import programInclustions_demotrip from '../components/demotrip/info_pages/program_inclusions'
import buyinInformation_demotrip from '../components/demotrip/info_pages/buyin_information'
import resort_demotrip from '../components/demotrip/info_pages/resort'
import activities_demotrip from '../components/demotrip/info_pages/activities'
import allActivities_demotrip from '../components/demotrip/info_pages/all_activities'
import restaurantReservation_demotrip from '../components/demotrip/info_pages/restaurant_reservation'
// import travel_demotrip from '../components/demotrip/info_pages/travel'
// import faq_demotrip from '../components/demotrip/info_pages/faq'
// import emergency_demotrip from '../components/demotrip/info_pages/emergency'
// import chatpage_demotrip from '../components/demotrip/chat'
import profilepage_demotrip from '../components/demotrip/winner_attendee_profile'
import qrcode_demotrip from '../components/demotrip/gifts/qrcode'
// import documents_demotrip from '../components/demotrip/documents'
// import maps_demotrip from '../components/demotrip/maps'
// import info_demotrip from '../components/demotrip/info'
import gifts_demotrip from '../components/demotrip/gifts/items'
import photoAlbum_demotrip from '../components/demotrip/photo_album/album'
import photos_demotrip from '../components/demotrip/photo_album/photos'

import infobooth_demotrip from '../components/demotrip/info_pages/infobooth'
import eveningevents_demotrip from '../components/demotrip/info_pages/eveningevents'
import safetyandsecurity_demotrip from '../components/demotrip/info_pages/safetyandsecurity'

// // gold club
// import homePage from '../components/goldclub/americas/home'
// import programInclustions from '../components/goldclub/americas/info_pages/program_inclusions'
// import buyinInformation from '../components/goldclub/americas/info_pages/buyin_information'
// import resort from '../components/goldclub/americas/info_pages/resort'
// import activities from '../components/goldclub/americas/info_pages/activities'
// import restaurant from '../components/goldclub/americas/info_pages/restaurant'
// import travel from '../components/goldclub/americas/info_pages/travel'
// import faq from '../components/goldclub/americas/info_pages/faq'
// import healthSafety from '../components/goldclub/americas/info_pages/healthsafety'
// import emergency from '../components/goldclub/americas/info_pages/emergency'
// import chatpage from '../components/goldclub/americas/chat'
// import profilepage from '../components/goldclub/americas/winner_attendee_profile'
// import qrcode from '../components/goldclub/americas/qrcode'
// import documents from '../components/goldclub/americas/documents'
// import maps from '../components/goldclub/americas/maps'
// import info from '../components/goldclub/americas/info'

import notificationItems from '../components/notifications/items'
import notificationDisplay from '../components/notifications/display'

import about from '../components/about/items'
import liabraries from '../components/about/liabraries'

Vue.use(VueRouter)

const routes = [
    { path:'/', redirect:'/sendotp'},
    // { path:'/', redirect:'/chattestpage'},
    // { path: '/chattestpage', name: 'chattestpage', component: chattestpage},
    { path: '/token', name: 'token', component: token},
    { path: '/trips', name: 'trips', component: trips, props:true},
    { path: '/sendotp', name: 'sendotp', component: sendotp},
    { path: '/verifyotp', name: 'verifyotp', component: verifyotp, props:true},
    { path: '/verifymobileotp', name: 'verifymobileotp', component: verifymobileotp, props:true},
    { path: '/verifydob', name: 'verifydob', component: verifydob, props:true},

    { path: '/notifications', name: 'notifications', component: notificationItems},
    { path: '/notifications/:notificationid', name: 'notificationDisplay', component: notificationDisplay},

    { path: '/about', name: 'about', component: about},
    { path: '/about/liabraries', name: 'liabraries', component: liabraries},

    // platinum club
    { path: '/platinumclub', name: 'homepage', component: homePagePC},
    // { path: '/platinumclub/programinclusions', name: 'programinclusions', component: programInclustionsPC},
    { path: '/platinumclub/buyininformation', name: 'buyininformation', component: buyinInformationPC},
    { path: '/platinumclub/resort', name: 'resort', component: resortPC},
    { path: '/platinumclub/activities', name: 'activities', component: activitiesPC, props:true},
    { path: '/platinumclub/allactivities', name: 'allactivities', component: allActivitiesPC},
    { path: '/platinumclub/restaurantreservation', name: 'restaurantreservation', component: restaurantReservationPC},
    // { path: '/platinumclub/travel', name: 'travel', component: travelPC},
    // { path: '/platinumclub/faq', name: 'faq', component: faqPC},
    // { path: '/platinumclub/emergency', name: 'emergency', component: emergencyPC},
    // { path: '/platinumclub/chatpage', name: 'chatpage', component: chatpagePC},
    { path: '/platinumclub/attendee/:attendeeid', name: 'profilepage', component: profilepagePC},
    { path: '/platinumclub/qrcode', name: 'qrcode', component: qrcodePC, props:true},
    // { path: '/platinumclub/documents', name: 'documents', component: documentsPC},
    // { path: '/platinumclub/maps', name: 'maps', component: mapsPC},
    // { path: '/platinumclub/info', name: 'info', component: infoPC},
    { path: '/platinumclub/gifts', name: 'gifts', component: giftsPC},
    { path: '/platinumclub/photoalbum', name: 'photoalbum', component: photoAlbumPC},
    { path: '/platinumclub/photos', name: 'photos', component: photosPC, props:true},
    { path: '/platinumclub/socialfeed', name: 'socialfeedplatinumclub', component: socialfeedplatinumclub},
    { path: '/platinumclub/keylocations', name: 'keylocationsplatinumclub', component: keylocationsplatinumclub},
    { path: '/platinumclub/shuttle', name: 'pcshuttle', component: pcshuttle},
    

    { path: '/platinumclub/infobooth', name: 'infobooth', component: infoboothPC},
    { path: '/platinumclub/eveningevents', name: 'eveningevents', component: eveningeventsPC},
    { path: '/platinumclub/safetyandsecurity', name: 'safetyandsecurity', component: safetyandsecurityPC},
    { path: '/platinumclub/departureinfo', name: 'departureinfo', component: departurePC},

    { path: '/platinumclub/galaseating', name: 'galaseating', component: galaSeatingPC},
    { path: '/platinumclub/guestqrcode', name: 'platinumclubguestqrcode', component: guestQRCode_platinumclub},
    { path: '/platinumclub/headshotpc', name: 'headshotpc', component: headshotPC},
    { path: '/platinumclub/executiveattendence', name: 'executiveattendence', component: executiveAttendence },
    { path: '/platinumclub/donate', name: 'donate', component: donate },
    { path: '/platinumclub/yourconfirmations', name: 'yourconfirmations', component: yourConfirmations },
    { path: '/platinumclub/survey', name: 'survey', component: survey },

    // Pinnacle
    { path: '/pinnacle', name: 'homepage', component: homePage_pinnacle},
    { path: '/pinnacle/resort', name: 'resort', component: resort_pinnacle},
    { path: '/pinnacle/oncampus', name: 'oncampus', component: oncampus_pinnacle},
    { path: '/pinnacle/herotile/:id', name: 'herotiledetails', component: herotiledetails_pinnacle},
    // { path: '/pinnacle/chat', name: 'chat', component: chat},
   
    // GCEMEA
    { path: '/gcemea', name: 'homepage', component: homePage_emea},
    // { path: '/gcemea/programinclusions', name: 'programinclusions', component: programInclustions_emea},
    { path: '/gcemea/buyininformation', name: 'buyininformation', component: buyinInformation_emea},
    { path: '/gcemea/resort', name: 'emearesort', component: resort_emea, props:true},
    { path: '/gcemea/activities', name: 'activities', component: activities_emea},
    { path: '/gcemea/allactivities', name: 'allactivities', component: allActivities_emea},
    { path: '/gcemea/restaurantreservation', name: 'restaurantreservation', component: restaurantReservation_emea},
    // { path: '/gcemea/travel', name: 'travel', component: travel_emea},
    // { path: '/gcemea/faq', name: 'faq', component: faq_emea},
    // { path: '/gcemea/emergency', name: 'emergency', component: emergency_emea},
    // { path: '/gcemea/chatpage', name: 'chatpage', component: chatpage_emea},
    { path: '/gcemea/attendee/:attendeeid', name: 'profilepage', component: profilepage_emea},
    { path: '/gcemea/qrcode', name: 'qrcode', component: qrcode_emea, props:true},
    // { path: '/gcemea/documents', name: 'documents', component: documents_emea},
    // { path: '/gcemea/maps', name: 'maps', component: maps_emea},
    // { path: '/gcemea/info', name: 'info', component: info_emea},
    { path: '/gcemea/gifts', name: 'gifts', component: gifts_emea},
    { path: '/gcemea/photoalbum', name: 'photoalbum', component: photoAlbum_emea},
    { path: '/gcemea/photos', name: 'gcemeaphotos', component: photos_emea, props:true},

    { path: '/gcemea/infobooth', name: 'infobooth', component: infobooth_emea},
    { path: '/gcemea/eveningevents', name: 'eveningevents', component: eveningevents_emea},
    { path: '/gcemea/safetyandsecurity', name: 'safetyandsecurity', component: safetyandsecurity_emea},
    { path: '/gcemea/departureinfo', name: 'departureinfo', component: departure_emea},
    { path: '/gcemea/resortcards', name: 'resortcards', component: resortCard_emea},
    //{ path: '/gcemea/headshot', name: 'headshot', component: headshot_emea},
    { path: '/gcemea/guestqrcode', name: 'emeaguestqrcode', component: guestQRCode_emea},
    { path: '/gcemea/headshotemea', name: 'headshotemea', component: headshotEmea},
    { path: '/gcemea/keylocations', name: 'keylocationsemea', component: keylocationsemea},
    { path: '/gcemea/socialfeed', name: 'socialfeedemea', component: socialfeedemea},
    { path: '/gcemea/shuttle', name: 'shuttleschedule', component: shuttleschedule},
    

    // gcamericas
    { path: '/gcamericas', name: 'homepage', component: homePage_americas},
    // { path: '/gcamericas/programinclusions', name: 'programinclusions', component: programInclustions_americas},
    { path: '/gcamericas/buyininformation', name: 'buyininformation', component: buyinInformation_americas},
    { path: '/gcamericas/resort', name: 'resort', component: resort_americas},
    { path: '/gcamericas/activities', name: 'activities', component: activities_americas},
    { path: '/gcamericas/allactivities', name: 'allactivities', component: allActivities_americas},
    { path: '/gcamericas/restaurantreservation', name: 'restaurantreservation', component: restaurantReservation_americas},
    // { path: '/gcamericas/travel', name: 'travel', component: travel_americas},
    // { path: '/gcamericas/faq', name: 'faq', component: faq_americas},
    // { path: '/gcamericas/emergency', name: 'emergency', component: emergency_americas},
    // { path: '/gcamericas/chatpage', name: 'chatpage', component: chatpage_americas},
    { path: '/gcamericas/attendee/:attendeeid', name: 'profilepage', component: profilepage_americas},
    { path: '/gcamericas/qrcode', name: 'qrcode', component: qrcode_americas, props:true},
    // { path: '/gcamericas/documents', name: 'documents', component: documents_americas},
    // { path: '/gcamericas/maps', name: 'maps', component: maps_americas},
    // { path: '/gcamericas/info', name: 'info', component: info_americas},
    { path: '/gcamericas/gifts', name: 'gifts', component: gifts_americas},
    { path: '/gcamericas/photoalbum', name: 'photoalbum', component: photoAlbum_americas},
    { path: '/gcamericas/photos', name: 'gcamericasphotos', component: photos_americas, props:true},

    { path: '/gcamericas/infobooth', name: 'infobooth', component: infobooth_americas},
    { path: '/gcamericas/eveningevents', name: 'eveningevents', component: eveningevents_americas},
    { path: '/gcamericas/safetyandsecurity', name: 'safetyandsecurity', component: safetyandsecurity_americas},
    { path: '/gcamericas/departureinfo', name: 'departureinfo', component: departure_americas},

    { path: '/gcamericas/herotile/:id', name: 'herotiledetails', component: herotiledetails_americas},
    { path: '/gcamericas/guestqrcode', name: 'americasguestqrcode', component: guestQRCode_americas},
    { path: '/gcamericas/headshotamericas', name: 'headshotamericas', component: headshotAmericas},
    { path: '/gcamericas/keylocations', name: 'keylocationsamericas', component: keylocationsamericas},
    { path: '/gcamericas/socialfeed', name: 'socialfeedamericas', component: socialfeedamericas},
    { path: '/gcamericas/giveback', name: 'giveback', component: giveback},

    // gcasia
    { path: '/gcasia', name: 'homepage', component: homePage_asia},
    // { path: '/gcasia/programinclusions', name: 'programinclusions', component: programInclustions_asia},
    { path: '/gcasia/buyininformation', name: 'buyininformation', component: buyinInformation_asia},
    { path: '/gcasia/resort', name: 'resort', component: resort_asia},
    { path: '/gcasia/activities', name: 'activities', component: activities_asia},
    { path: '/gcasia/allactivities', name: 'allactivities', component: allActivities_asia},
    { path: '/gcasia/restaurantreservation', name: 'restaurantreservation', component: restaurantReservation_asia},
    // { path: '/gcasia/travel', name: 'travel', component: travel_asia},
    // { path: '/gcasia/faq', name: 'faq', component: faq_asia},
    // { path: '/gcasia/emergency', name: 'emergency', component: emergency_asia},
    // { path: '/gcasia/chatpage', name: 'chatpage', component: chatpage_asia},
    { path: '/gcasia/attendee/:attendeeid', name: 'profilepage', component: profilepage_asia},
    { path: '/gcasia/qrcode', name: 'qrcode', component: qrcode_asia, props:true},
    // { path: '/gcasia/documents', name: 'documents', component: documents_asia},
    // { path: '/gcasia/maps', name: 'maps', component: maps_asia},
    // { path: '/gcasia/info', name: 'info', component: info_asia},
    { path: '/gcasia/gifts', name: 'gifts', component: gifts_asia},
    { path: '/gcasia/photoalbum', name: 'photoalbum', component: photoAlbum_asia},
    { path: '/gcasia/photos', name: 'gcasiaphotos', component: photos_asia, props:true},

    { path: '/gcasia/infobooth', name: 'infobooth', component: infobooth_asia},
    { path: '/gcasia/eveningevents', name: 'eveningevents', component: eveningevents_asia},
    { path: '/gcasia/safetyandsecurity', name: 'safetyandsecurity', component: safetyandsecurity_asia},
    { path: '/gcasia/departureinfo', name: 'departureinfo', component: departure_asia},

    { path: '/gcasia/herotile/:id', name: 'herotiledetails', component: herotiledetails_asia},
    { path: '/gcasia/guestqrcode', name: 'asiaguestqrcode', component: guestQRCode_asia},
    { path: '/gcasia/headshotasia', name: 'headshotasia', component: headshotAsia},
    { path: '/gcasia/shuttle', name: 'shuttle', component: shuttle},
    { path: '/gcasia/keylocations', name: 'keylocationsasia', component: keylocationsasia},
    { path: '/gcasia/socialfeed', name: 'socialfeedasia', component: socialfeedasia},


    // demotrip
    { path: '/demotrip', name: 'homepage', component: homePage_demotrip},
    // { path: '/demotrip/programinclusions', name: 'programinclusions', component: programInclustions_demotrip},
    { path: '/demotrip/buyininformation', name: 'buyininformation', component: buyinInformation_demotrip},
    { path: '/demotrip/resort', name: 'resort', component: resort_demotrip},
    { path: '/demotrip/activities', name: 'activities', component: activities_demotrip},
    { path: '/demotrip/allactivities', name: 'allactivities', component: allActivities_demotrip},
    { path: '/demotrip/restaurantreservation', name: 'restaurantreservation', component: restaurantReservation_demotrip},
    // { path: '/demotrip/travel', name: 'travel', component: travel_demotrip},
    // { path: '/demotrip/faq', name: 'faq', component: faq_demotrip},
    // { path: '/demotrip/emergency', name: 'emergency', component: emergency_demotrip},
    // { path: '/demotrip/chatpage', name: 'chatpage', component: chatpage_demotrip},
    { path: '/demotrip/attendee/:attendeeid', name: 'profilepage', component: profilepage_demotrip},
    { path: '/demotrip/qrcode', name: 'qrcode', component: qrcode_demotrip, props:true},
    // { path: '/demotrip/documents', name: 'documents', component: documents_demotrip},
    // { path: '/demotrip/maps', name: 'maps', component: maps_demotrip},
    // { path: '/demotrip/info', name: 'info', component: info_demotrip},
    { path: '/demotrip/gifts', name: 'gifts', component: gifts_demotrip},
    { path: '/demotrip/photoalbum', name: 'photoalbum', component: photoAlbum_demotrip},
    { path: '/demotrip/photos', name: 'photos', component: photos_demotrip, props:true},

    { path: '/demotrip/infobooth', name: 'infobooth', component: infobooth_demotrip},
    { path: '/demotrip/eveningevents', name: 'eveningevents', component: eveningevents_demotrip},
    { path: '/demotrip/safetyandsecurity', name: 'safetyandsecurity', component: safetyandsecurity_demotrip},



    // gold club
    // { path: '/gcamericas/notifications', name: 'notifications', component: notificationItems},
    // { path: '/gcamericas', name: 'gcamericas', component: homePage},
    // { path: '/gcamericas/programinclusions', name: 'programinclusions', component: programInclustions},
    // { path: '/gcamericas/buyininformation', name: 'buyininformation', component: buyinInformation},
    // { path: '/gcamericas/resort', name: 'resort', component: resort},
    // { path: '/gcamericas/activities', name: 'activities', component: activities},
    // { path: '/gcamericas/restaurants', name: 'restaurant', component: restaurant},
    // { path: '/gcamericas/travel', name: 'travel', component: travel},
    // { path: '/gcamericas/faq', name: 'faq', component: faq},
    // { path: '/gcamericas/healthsafety', name: 'healthsafety', component: healthSafety},
    // { path: '/gcamericas/emergency', name: 'emergency', component: emergency},
    // { path: '/gcamericas/chatpage', name: 'chatpage', component: chatpage},
    // { path: '/gcamericas/profile', name: 'profilepage', component: profilepage},
    // { path: '/gcamericas/qrcode', name: 'qrcode', component: qrcode},
    // { path: '/gcamericas/documents', name: 'documents', component: documents},
    // { path: '/gcamericas/maps', name: 'maps', component: maps},
    // { path: '/gcamericas/info', name: 'info', component: info},
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
})

export default router