<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div>
            <template v-if="loading">
                <!-- <a type="button" href="./../assets/platinumclub/MC_PC_2022_DA_Monaco_map.pdf" target="_blank">View Map</a> -->
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/2024/EMEA_KeyLocationsBannerNew_1125x450.png"></v-img>

                <v-card flat>
                    <div>
                        <v-card-title class="pb-2">Rome Cavalieri</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">Via Alberto Cadlolo,<br>101, 00136 Roma RM,<br>Italy</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Rome Cavalieri', 41.9187486, 12.4467266)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Rome Cavalieri', 41.91912378446614, 12.446777397700819)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Villa Miani</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">Via Trionfale,<br> 151, 00136 Roma RM,<br>Italy</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Villa Miani', 41.9177487, 12.4487571)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Villa Miani', 41.91681839171724, 12.44692036886539)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Leonardo da Vinci–Fiumicino Airport (FCO)</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">via dell'Airport di Fiumicino 320,<br>00054 Fiumicino,<br>Italy</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Leonardo da Vinci–Fiumicino Airport (FCO)', 41.7952402, 12.2549486)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Leonardo da Vinci–Fiumicino Airport (FCO)', 41.803639119350855, 12.251899640026132)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Trevi Fountain</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">Piazza di Trevi,<br>00187 Roma RM,<br>Italy</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Trevi Fountain', 41.9009954, 12.4832851)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Trevi Fountain', 41.900995450224876, 12.483292782358614)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Colosseum</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">Piazza del Colosseo,<br>1, 00184 Roma RM,<br>Italy</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Colosseum', 41.89018, 12.49231)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Colosseum', 41.89032997331639, 12.492198711193671)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Spanish Steps</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">Piazza di Spagna,<br>00187 Roma RM,<br>Italy</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Spanish Steps', 41.9059622, 12.4827594)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Spanish Steps', 41.90616016326705, 12.482807979415183)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Pantheon</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">1 Piazza della Rotonda,<br>00186 Roma RM,<br>Italy</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Pantheon', 41.8985558, 12.4768317)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Pantheon', 41.903909255583244, 12.474197617544283)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Monument to Victor Emmanuel II</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">Piazza Venezia,<br>00186 Roma RM,<br>Italy</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Monument to Victor Emmanuel II', 45.4388, 10.9925)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Monument to Victor Emmanuel II', 41.896778495664684, 12.482959454450592)">Get Direction</a>
                                </div>
                            </div>

                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Roman Forum</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">00186 Rome,<br>Metropolitan City of Rome Capital,<br>Italy</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Roman Forum', 41.8901662, 12.4874479)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Roman Forum', 41.89726530049055, 12.485870590470768)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Villa Borghese</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">00197 Rome,<br>Metropolitan City of Rome Capital,<br>Italy</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Villa Borghese', 41.9138906, 12.4844272)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Villa Borghese', 41.92077157535635, 12.485527267737636)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>

                    <div>
                        <v-card-title class="pb-2">Piazza Navona</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">Piazza Navona,<br>00186 Roma RM,<br>Italy</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Piazza Navona', 41.8989591, 12.4730819)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Piazza Navona', 41.905953410383404, 12.469734422013563)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>

                    <div>
                        <v-card-title class="pb-2">Campo de' Fiori</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">Campo de' Fiori,<br>00186 Roma RM,<br>Italy</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Campo de\' Fiori', 41.895573, 12.472229)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Campo de\' Fiori', 41.895812139459835, 12.472116691117005)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>

                    <div>
                        <v-card-title class="pb-2">Vatican Museums</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">00120 Vatican City</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Vatican Museums', 41.9062576, 12.454108)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Vatican Museums', 41.90666336188224, 12.45357691222569)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>

                    <div>
                        <v-card-title class="pb-2">St. Peter's Basilica</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">Piazza San Pietro,<br>00120 Città del Vaticano,<br>Vatican City</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('St. Peter\'s Basilica', 41.9021573, 12.4531853)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('St. Peter\'s Basilica', 41.902374214979076, 12.45392595640179)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>

                    <div>
                        <v-card-title class="pb-2">Shuttle Drop Off | Via Bissolati</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">Via Leonida Bissolati,<br>35/39, 00187 Roma RM,<br>Italy</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Shuttle Drop Off | Via Bissolati', 41.905573586737425, 12.491692491182823)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Shuttle Drop Off | Via Bissolati', 41.90574223868241, 12.49212915352334)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                </v-card>

                <!-- <v-card class="ma-4">
                    <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/GCAmercasResortMap_400x220.png"></v-img>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <a type="maplink" class="pr-2" href="/assets/gcasia/resort_map_9.pdf" target="_blank">View Map</a>
                    </v-card-actions>
                </v-card> -->

                <!-- <overview :content="overviewContent" v-if="overviewContent"></overview>

                <accordians :content="accordianContent" v-if="accordianContent"></accordians> -->

            </template>
            
        </div>

        <!-- <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getData('hotel')">Refresh Now</v-btn>
            </template>
        </v-snackbar> -->

    </div>
</template>

<script>
// import overview from './sub_components/overview'
// import accordians from './sub_components/accordians'

import {pageMixin} from './info_page_mixin'

export default{
    mixins:[pageMixin],
    // components:{
    //     'overview':overview,
    //     'accordians':accordians
    // },
    methods:{
        // fn_updateAvailable: function(dataObj){
        //     let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
        //     if(updateObj.pageName==='hotel'){
        //         if(this.overviewContent || this.accordianContent){
        //             this.snackbarMsg = true;
        //         }
        //         else{
        //             this.fn_getData('hotel');
        //         }
        //     }
        // }

        fn_openLocationOnMap: function(placeName, latitude, longitude){
            let dataObj = {};
            dataObj.PlaceName = placeName;
            dataObj.Latitude = latitude;
            dataObj.Longitude = longitude;
           
            this.postData('OpenMaps', dataObj, 
                {
                    onSuccess: function(response){
                        console.log("Success Map");
                        console.log(response);
                    },
                    onError: function(error){
                        console.log("Error Map");
                        console.log(error);
                    }
                }
            );
        }
    },
    mounted(){
        this.$root.fn_postClickMetrics('Explore Tiles', 'Key Locations', null);
        // window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        this.fn_getPageDataFromServer('keylocations');
    }
}
</script>