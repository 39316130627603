import { CometChat } from "@cometchat-pro/chat";
import axios from 'axios';

let tripSiteId = null;
let appID = null;
let region = null;

export const cometchatLogin = {
    fn_initCometChat: function(ref, tripId, callback){
        tripSiteId = tripId;
        if(ref.$root.Envirenment == 3){
            if(tripId == 20){
                appID = "24605346e76a2d4f";
                region = "us";
            }
            else if(tripId == 19){
                appID = "246054f0d048d41b";
                region = "eu";
            } 
            else if(tripId == 17){
                appID = "246927ecf0375cf7";
                region = "us";
            } 
            else if(tripId == 18){
                appID = "246929cf15c8229c";
                region = "in";
            }  
        }
        else{
            if(tripId == 20){
                appID = "263399ff0fe0210e";
                region = "us";
            }
            else if(tripId == 19){
                appID = "244928729b252d50";
                region = "in";
            }
            else if(tripId == 17){
                appID = "246937f2ee4a480f";
                region = "us";
            }
            else if(tripId == 18){
                appID = "2470234b0a7f19a5";
                region = "in";
            }
        }
        // if(tripId == 12){
        //     appID = "2454441df333baef";
        // }
        // else if(tripId == 15){
        //     appID = "244928729b252d50";
        // }
        
        // let region = "in";
        let appSetting = new CometChat.AppSettingsBuilder()
                            .subscribePresenceForAllUsers()
                            .setRegion(region)
                            .autoEstablishSocketConnection(true)
                            .build();
        CometChat.init(appID, appSetting).then(
            () => {
                console.log("Initialization completed successfully");
                this.fn_loginToCometChat(ref, callback);
            }, error => {
                callback('error');
                console.log("Initialization failed with error:", error);
            }
        );
    },

    fn_loginToCometChat: function(ref, callback){
        CometChat.getLoggedinUser().then(
            user => {
                if(!user){
                    this.fn_login(ref, callback);
                }
                else{
                    console.log("Already logged in");

                    console.log(user);

                    ref.$root.isTestUser = user?.metadata?.IsTest ? true : false;

                    let is_pn_registered = localStorage.getItem("pn_registered_"+tripSiteId) ? true : false;    

                    if(!is_pn_registered){
                        CometChat.logout().then(
                            () => {
                                this.fn_login(ref, callback);
                                console.log("Logout completed successfully");
                            },error=>{
                                console.log("Logout failed with exception:",{error});
                            }
                        );
                    }
                    else{
                        callback();
                    }
                }
            }, error => {
                console.log("Something went wrong", error);
                callback('error');
            }
        );
    },

    fn_login: function(ref, callback){
        ref.$root.fn_getToken(token=>{
            if(token == 'error'){
                ref.$root.fn_showToast('exception');
                callback('error');
            }
            else{
                var headersObj = {
                    headers: { 
                        'Authorization': 'bearer '+token,
                        }
                    };

                axios.get(ref.$root.baseUrl + "/api/mobileapp/cometchat/attendee/gettoken", headersObj, {timeout:30000})
                .then(response => {
                    let dataObj = JSON.parse(response.data);
                    if(dataObj?.data?.length){
                        CometChat.login(dataObj.data[0].authToken).then(
                            user => {
                                // this.fn_registerToken(ref, dataObj.data[0].authToken, callback);
                                this.fn_getFirebaseRegistrationToken(ref, dataObj.data[0].authToken, callback);
                                console.log("Login Successful:", { user });
                                ref.$root.isTestUser = user?.metadata?.IsTest ? true : false;
                            }, error => {
                                console.log("Login failed with exception:", { error });
                            }
                        );
                    }
                    else{
                        callback('error');
                    }
                }).catch(e => {
                    console.log(e);
                    callback('error');
                })
            }
        })
    },

    // fn_registerToken: function(ref, fcmAuthToken, callback){
    //     let self = this;
    //     ref.getData('FirebaseRegistrationToken', null,
    //     {
    //         onSuccess: function(response){
    //             console.log(response);
    //             CometChat.registerTokenForPushNotification(response).then(() => {
    //                 console.log('push notification registered');
    //                 localStorage.setItem("push_notification_registered_"+tripSiteId, 1);            
    //                 if(ref.$root.platform == 'ios'){
    //                     self.fn_registerChatUserForIos(ref, fcmAuthToken, response, callback)
    //                 }
    //                 else{
    //                     callback();
    //                 }
    //             }),(error) => {
    //                 console.log("Error in [registring for push notification]", error);
    //             }
    //         },
    //         onError: function(error){
    //             console.log(error);
    //             callback('error');
    //         }
    //     });
    // },

    fn_getFirebaseRegistrationToken: function(ref, authToken, callback){
        let self = this;
        ref.getData('FirebaseRegistrationToken', null,
        {
            onSuccess: function(response){
                console.log(response);
                self.fn_registerChatUserForIos(ref, authToken, response, callback);
            },
            onError: function(error){
                console.log(error);
                callback('error');
            }
        });
    },

    fn_registerChatUserForIos: function(ref, authToken, fcmRegistrationToken, callback){
        let dataObj = {};
        dataObj.fcmAuthToken = authToken;
        dataObj.fcmRegistrationToken = fcmRegistrationToken;
        dataObj.cometAppId = appID;
        dataObj.cometAppRegion = region;
        dataObj.tripId = tripSiteId.toString();
        console.log(JSON.stringify(dataObj));
        ref.postData('LoginRegisterChatUser', dataObj,
        {
            onSuccess: function(response){
                console.log(response);
                console.log('user registered');
                localStorage.setItem("pn_registered_"+tripSiteId, 1);
                callback();
            },
            onError: function(error){
                console.log('before error');
                console.log(error);
                console.log('user not registered');
                callback('error');
            }
        });
    },
}
