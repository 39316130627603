<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <!-- <v-toolbar-title>{{pageName}}</v-toolbar-title> -->
            <v-toolbar-title>Survey</v-toolbar-title>
        </v-toolbar>

        <div style="padding-bottom: 30px;">
            <template v-if="loading">
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <!-- <v-img height="168" :lazy-src="$root.imagePlaceholder" src="../assets/2024/PlatinumClub_Header_Headshots_1125x450.jpg"></v-img> -->

                <div>
                    <v-card flat class="zindex2 transparentbg">
                        <v-card-text><p>Check back for updates!</p></v-card-text>
                    </v-card>
                </div>

                <template>

                    <!-- <overview :content="pageOverview.ComponentText" v-if="pageOverview && !(pageOverview.ComponentText == null || pageOverview.ComponentText == '')"></overview> -->

                    <!-- <div class="ma-4" :class="{'mt-0' : pageOverview && !(pageOverview.ComponentText == null || pageOverview.ComponentText == '')}" v-if="headshotContent && headshotContent != null"> -->
                    <div class="ma-4 mt-0 ml-0 mr-0">
                        <v-card flat class="">
                            <v-list class="pa-0" three-line>
                                <template v-for="(item, index) in rateItemList">
                                    <v-list-item :key="index" class="pt-2 pb-2 zindex2 attendeelist_customformatting">
                                        <v-list-item-content>
                                            <v-list-item-title v-if="item.CustomIdentifier == 'Activity'" class="font-weight-bold" style="font-size: 12pt;">Activity: {{ item.ActivityName }}</v-list-item-title>
                                            <v-list-item-title v-else class="font-weight-bold" style="font-size: 12pt;">{{ item.Title }}</v-list-item-title>
                                            <v-list-item-subtitle class="black--text pt-1">Date: {{item.SurveyStartDate | moment("MMM DD, YYYY")}}</v-list-item-subtitle>
                                            <v-list-item-subtitle class="black--text pt-3">
                                                <v-btn @click="fn_editHeadShot(index)" :color="$root.themeColor" aria-label="Click to go next step." outlined>Rate Now</v-btn>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider :key="index+'divider'" v-if="index != rateItemList.length - 1"></v-divider>
                                </template>
                                <v-divider style="border: 6px solid rgba(0, 0, 0, 0.12);" v-if="ratedItemList && ratedItemList.length > 0"></v-divider>
                                <div v-if="ratedItemList && ratedItemList.length > 0" class="pl-4 pt-4">Past Servey</div>
                                <template v-for="(item, index) in ratedItemList">
                                    <v-list-item :key="index" class="pt-2 pb-2 zindex2 attendeelist_customformatting">
                                        <v-list-item-content>
                                            <v-list-item-title v-if="item.CustomIdentifier == 'Activity'" class="font-weight-bold" style="font-size: 12pt;">Activity: {{ item.ActivityName }}</v-list-item-title>
                                            <v-list-item-title v-else class="font-weight-bold" style="font-size: 12pt;">{{ item.Title }}</v-list-item-title>
                                            <v-list-item-subtitle class="black--text pt-1">Date: {{item.SurveyStartDate | moment("MMM DD, YYYY")}}</v-list-item-subtitle>
                                            <v-list-item-subtitle class="pt-2">Rating: <span class="black--text">{{item.Rating}}</span><v-icon class="pb-1" :color="$root.themeColor">mdi-star</v-icon></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider :key="index+'divider'"></v-divider>
                                    <!-- <v-divider :key="index+'divider'" v-if="index != ratedItemList.length - 1"></v-divider> -->
                                </template>
                            </v-list>
                            
                        </v-card>
                    </div>
                </template>
                

            </template>
            

            <!-- <template v-if="!loading && headshotContent == null && !error && HeadShotSlotStatus">
                <div class="placeholder-wrapper pickyourslot_section content-page-placeholder pa-4">
                    <div center-align>
                        <v-btn class="mt-4" outlined @click="fn_selectSlot"><v-icon left>mdi-image-size-select-large</v-icon> Click to Schedule</v-btn>
                    </div>
                </div>
            </template> -->
            
        </div>

        <div class="pcfooter_image">
            <img src="../assets/2024/PlatinumClub_SideWaveBG_1120x920.png">
        </div>
        <div class="pcfooter_wave_image">
            <img src="../assets/2024/PlatinumClub_BottomWave_1120x112.png">
        </div>

        <!-- <div class="pcfooter_image">
            <img v-show="0" src="../assets/PlatinumClub2023_FooterImage_420x200.png">
        </div> -->

        <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getDetails">Refresh Now</v-btn>
            </template>
        </v-snackbar>

        <v-dialog v-model="SlotSelectionDialog" content-class="platinumclub" scrollable transition="dialog-bottom-transition" fullscreen persistent>
            <v-card>
                <v-card-title :style="{ backgroundColor: $root.themeColor}" :color="$root.themeColor"  class="title pb-4 pl-4 white--text" style="flex-wrap:nowrap;word-break: break-word;">
                    <v-btn :color="$root.themeColor" icon class="mr-4 white--text" @click="fn_closeForm">
                        <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
                    </v-btn>
                    Survey
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="scrollable-content form_dialog_text pt-4 platinumclub_custom_formatting">
                    <template v-if="loading">
                        <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
                    </template>
                    <template v-else>
                        <div class="pt-6">
                            <p v-if="itemObj.CustomIdentifier == 'Activity'" style="font-size: 14pt;" class="text-center pb-2"><b>Activity</b></p>
                            <p v-else style="font-size: 14pt;" class="text-center pb-2"><b>{{ itemObj.Title}}</b></p>
                            <p class="text-center pb-2" style="font-size: 12pt;">Date: {{ itemObj.SurveyStartDate | moment("MMM DD, YYYY") }}</p>
                            <p v-if="itemObj.CustomIdentifier == 'Activity'" class="text-center pb-4" style="font-size: 12pt;">Please rate your <b>{{ itemObj.ActivityName }}</b> experience:</p>
                            <p v-else class="text-center pb-4" style="font-size: 12pt;" v-html="itemObj.Question"></p>
                            <!-- <p class="text-center pb-4" style="font-size: 12pt;">Please rate your <b>{{ itemObj.title }}</b> experience:</p> -->
                        </div>
                        <div class="text-center">
                            <v-rating v-model="rating" background-color="indigo lighten-3" :color="$root.themeColor" size="30"></v-rating>
                        </div>
                        <div class="text-center pt-4">
                            <v-btn style="min-width: 130px;" :disabled="rating == null" :color="$root.themeColor" tile class="btn-white mt-1 pl-3 pr-3 white--text confirmation_btn" @click="fn_SaveHeadShotSelection">Submit</v-btn>
                        </div>
                    </template>
                    
                </v-card-text>
                <!-- <v-divider></v-divider>
                <v-card-actions v-if="!loading">
                    <v-spacer></v-spacer>
                    <v-btn block :disabled="MobileHeadShotSlotID == null" :color="$root.themeColor" tile class="btn-white mt-1 pl-3 pr-3 white--text confirmation_btn" @click="fn_SaveHeadShotSelection">Save</v-btn>
                </v-card-actions> -->
                <div class="pcfooter_image">
                    <img width="100%" src="../assets/2024/PlatinumClub_SideWaveBG_1120x920.png">
                </div>
                <div class="pcfooter_wave_image">
                    <img width="100%" src="../assets/2024/PlatinumClub_BottomWave_1120x112.png">
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="downloading" persistent width="300">
            <v-card>
                <v-card-text class="pt-4">Downloading...
                    <v-progress-linear indeterminate color="primary" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="slotFullSnackbarMsg" :timeout="1000">
            This timeslot is full, Please select other timeslot!
        </v-snackbar>

    </div>
</template>

<script>
// import accordians from './sub_components/accordians'
// import overview from './sub_components/overview'
import {pageMixin} from './info_page_mixin'

import axios from 'axios';

export default{
    mixins:[pageMixin],
    components:{
        // 'overview':overview
    },
    data() {
        return {
            error:false,
            headshotContent:null,
            SlotSelectionDialog: false,
            SlotList: [],
            MobileHeadShotSlotID: null,
            imageName: null,
            imageUrl: null,
            downloading:false,
            pageOverview: null,
            slotDate: null,
            slotListItems: [],
            HeadShotSlotStatus: null,
            GrouppedNavList: [],
            snackbarMsg: false,
            slotFullSnackbarMsg: false,
            Items: [
                // { title: 'Community Service Project', date: '2024-09-11T04:49:14.327', israted: true, rating: 5, type: 2 },
                // { title: 'Activity : Golf at Kapalua Plantation Course', date: '2024-09-11T04:49:14.327', israted: null, rating: null, type: 1 },
                // { title: 'Welcome Reception abd Dinner', date: '2024-09-11T04:49:14.327', israted: true, rating: 5, type: 2 },
                // { title: 'Gifting Suite', date: '2024-09-11T04:49:14.327', israted: null, rating: 3, type: 1 },
            ],
            index: null,
            itemObj: {},
            rating: null
        }
    },
    methods:{
        fn_updateAvailable: function(){
            console.log('update available');
        },

        fn_dateChange: function(){
            this.MobileHeadShotSlotID = null;
        },

        fn_editHeadShot: function(index){
            this.index = index;
            var item = this.rateItemList[index];
            this.itemObj = item;
            this.rating = null
            // this.slotDate = this.headshotContent.SlotDate;
            // this.MobileHeadShotSlotID = this.headshotContent.MobileHeadShotSlotID;
            this.SlotSelectionDialog = true;
            // this.$root.fn_postClickMetrics('Headshots', 'Re-Schedule', this.MobileHeadShotSlotID);
            // this.fn_getSlotList();
        },

        fn_closeForm: function(){
            this.rating = null;
            this.SlotSelectionDialog = false;
        },

        fn_getDetails: function(){

            this.loading = true;
            this.error = false;
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loading = false;
                }
                else{
                    var headersObj = {
                        headers: { 
                            'Authorization': 'bearer '+token,
                            }
                        };

                    axios.get(this.$root.baseUrl + "/api/mobileapp/attendee/survey/list", headersObj, {timeout:30000})
                    .then(response => {
                        this.loading = false;
                        console.log(response);
                        this.snackbarMsg = false;
                        this.Items = response.data;
                        this.fn_photoAuthThumbnail();
                        // if(this.headshotContent){
                        //     this.imageName = this.headshotContent.PhotoName;
                        //     this.imageUrl = this.headshotContent.AttendeePhotoURL;
                        // }
                        
                    }).catch(e => {
                        this.loading = false;
                        // this.error = true;
                        console.log(e);
                        // let toastMsg = e && e.message ? e.message : 'exception';
                        // this.$root.fn_showToast(toastMsg);
                    })
                }
            })

        },

        fn_SaveHeadShotSelection: function(){
            // this.SlotSelectionDialog = false;

            let self = this;
            this.loading = true;
            this.error = false;

            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loading = false;
                }
                else{
                    var headersObj = {
                        headers: { 
                            'Authorization': 'bearer '+token,
                            }
                        };

                    let postObj = {};
                    postObj.MobileSurveyID = this.itemObj.MobileSurveyID;
                    postObj.Rating = this.rating;

                    axios.post(this.$root.baseUrl + "/api/mobileapp/attendee/submit/survey", postObj, headersObj, {timeout:30000})
                    .then(response => {
                        this.loading = false;
                        console.log(response);
                        // this.snackbarMsg = false;
                        this.rateItemList[this.index].Rating = this.rating;
                        this.rateItemList[this.index].IsSurveySubmitted = true;
                        this.SlotSelectionDialog = false;
                        // this.fn_getDetails();
                        
                    }).catch(e => {
                        this.loading = false;
                        
                        console.log(e);
                        if(e.response && e.response.data && e.response.data.Message && e.response.data.Message == 'Oops slot is full'){
                            self.slotFullSnackbarMsg = true;
                            self.fn_clearSlotTime();
                        }
                    })
                }
            })
        },

        fn_clearSlotTime: function(){
            this.MobileHeadShotSlotID = null;
            this.fn_getSlotList();
        },

        fn_selectSlot: function(){
            this.slotDate = null;
            this.MobileHeadShotSlotID = null;
            this.SlotSelectionDialog = true;
            // this.$root.fn_postClickMetrics('Headshots', 'Click to Schedule', null);
            this.fn_getSlotList();
        },

        fn_getSlotList: function(){
            this.loading = true;
            this.error = false;
            this.$root.fn_getToken(token=>{
                if(token == 'error'){
                    this.loading = false;
                }
                else{
                    var headersObj = {
                        headers: { 
                            'Authorization': 'bearer '+token,
                            }
                        };

                    axios.get(this.$root.baseUrl + "/api/mobileapp/headshots/available/slot", headersObj, {timeout:30000})
                    .then(response => {
                        this.loading = false;
                        console.log(response);
                        this.snackbarMsg = false;
                        this.SlotList = response.data && response.data.SlotDateList && response.data.SlotDateList.length ? response.data.SlotDateList : [];
                    }).catch(e => {
                        this.loading = false;
                        console.log(e);
                    })
                }
            })

        },

        fn_getImage: function(imagename, imageurl){
            // this.$root.fn_postClickMetrics('Headshots', 'Download Photo', null);
            this.imageName = imagename;
            this.imageUrl = imageurl;
            if(this.$root.platform === 'ios'){
                this.fn_openImage();
            }
            else{
                this.$root.fn_checkStoragePermission(response=>{
                    if(JSON.parse(response)){
                        this.fn_openImage();
                    }
                    else{
                        this.$root.fn_showToast('Please allow storage permission');
                    }
                })
            }
        },

        fn_openImage: function(){
            let self = this;
            this.getData('GetImage', this.imageName, 
            {
                onSuccess: function(response){
                    console.log(response);
                    let isDownloaded = JSON.parse(response);
                    if(isDownloaded){
                        if(self.$root.platform === 'ios'){
                            self.fn_saveImage();
                        }
                        else{
                            self.fn_downloadImage();
                        }
                    }
                    else{
                        self.fn_downloadImage();
                    }
                    
                },
                onError: function(error){
                    console.log(error);
                    if(error.dataKey && !JSON.parse(error.dataKey)){
                        self.fn_downloadImage();
                    }
                }
            });
        },

        fn_downloadImage: function(){
            let self = this;
            let dataObj = {};
            dataObj.FileName = this.imageName;
            dataObj.FileUrl = this.$root.baseUrl + this.imageUrl;
            dataObj.DisplayName = this.imageName;
            this.$root.platform === 'android' ? dataObj.IsImageFile = true : '';
            this.downloading = true;
            this.getServerData('DownloadFile', dataObj, {
                onSuccess: function(response){
                    console.log(response);
                    self.downloading = false;
                    self.$root.platform === 'android' ? self.$root.fn_showToast('Added to Photos') : '';
                    if(self.$root.platform === 'ios'){
                        self.fn_saveImage();
                    }
                }, 
                onError: function(error){
                    console.log(error);
                    self.downloading = false;
                    self.$root.fn_showToast('exception');
                }, 
            });
        },

        fn_saveImage: function(){
            let self = this;
            this.getData('SaveImageToPhotoLibrary', this.imageName, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.$root.fn_showToast('Added to Photos');
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        async fn_photoAuthThumbnail() {

            // this.loading = true;
            // this.error = false;
            let self = this;

            
            for (let i = 0; i < this.headshotContent.Photos.length; i++) {
              if(self.headshotContent.Photos[i].AttendeeThumbnailURL && !self.headshotContent.Photos[i].AttendeeThumbnailURL.startsWith('data:image/')){
                try {
                    const token = await new Promise((resolve, reject) => {
                        this.$root.fn_getToken((token) => {
                            if (token === 'error') {
                                reject('Token error');
                            } else {
                                resolve(token);
                            }
                        });
                    });

                    console.log(token + "Token Called");

                    const headersObj = {
                        headers: { 
                            'Authorization': 'Bearer ' + token,
                        }
                    };

                    
                    console.log(self.$root.baseUrl);
                    const response = await axios.get(self.$root.baseUrl + self.headshotContent.Photos[i].AttendeeThumbnailURL, headersObj, {timeout: 30000});
                    
                    // Update album item with the retrieved data
                    console.log(response.data);
                    self.headshotContent.Photos[i].AttendeeThumbnailURL = 'data:image/png;base64,' + response.data;
                    
                    console.log(self.headshotContent.Photos[i].ImageUrl);
                    self.$forceUpdate();
                } catch (e) {
                    self.error = true;
                    console.log(e);
                    let toastMsg = e && e.message ? e.message : 'exception';
                    self.$root.fn_showToast(toastMsg);
                    break; // Stop processing on error
                }
              }
            }

            this.loading = false;
            this.error = false;
        },
    },
    mounted(){
        window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        this.$root.fn_postClickMetrics('Survey', 'Default', null);
        // this.fn_getDetails();
        // this.fn_refresh();
    },

    computed:{
        rateItemList() {
            return this.Items.filter(item => {
                return item.IsSurveySubmitted != true ;
            });
        },

        ratedItemList() {
            return this.Items.filter(item => {
                return item.IsSurveySubmitted == true ;
            });
        }
            
    }
}
</script>

<style>
.v-rating .v-icon{
    padding: 0px !important;
}

</style>