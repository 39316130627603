<template>
    <div style="min-height:100vh;overflow;auto">
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>Notifications</v-toolbar-title>
        </v-toolbar>

        <div>
            
            <template v-if="error">
                <div style="text-align:center;" class="pa-4">
                    <p>Something went wrong. Please try again.</p>
                    <br>
                    <v-btn outlined @click="fn_reload"><v-icon left>refresh</v-icon> Retry</v-btn>
                </div>
            </template>

            <template v-else>

                <v-list three-line>
                    <template v-for="(item, index) in notifications">
                        <v-list-item :class="{'font-heavy':!item.isRead}" :key="index" @click="fn_viewDetails(item.SendPushNotificationID)">
                            <v-list-item-icon>
                                <v-icon>info_outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{item.NotificationTitle}}</v-list-item-title>
                                <v-list-item-subtitle>{{item.NotificationShortDescription}}</v-list-item-subtitle>
                                <v-list-item-subtitle>{{item.NotificationSentOn | moment("MMM Do YYYY, hh:mm a")}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider inset v-if="notifications.length - 1 != index" :key="'divider'+index"></v-divider>    
                    </template>
                    

                    <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
                        <div slot="no-more"></div>
                        <div slot="no-results" class="pa-4 light-opacity-color">No new notifications</div>
                    </infinite-loading>
                </v-list>        
            </template>
            
        </div>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import axios from 'axios';

    export default{
        data(){
            return{
                error:false,
                notifications:[],
                infiniteId: +new Date(),
                pageOffset:0,
                timeoutid:null,
            }
        },
        components: {
            InfiniteLoading,
        },
        methods:{
            fn_reload: function(){
                this.error = false;
                this.infiniteId = +new Date();
            },

            fn_viewDetails: function(notificationId){
                this.$router.push({path:'/notifications/'+notificationId});
            },

            infiniteHandler($state) { 

                if(!navigator.onLine){
                    this.showToast("nointernet");
                    return;
                }

                this.error = false;
                this.$root.fn_getToken(token=>{
                    if(token == 'error'){
                        this.error = true;
                        this.$root.fn_showToast('exception');
                    }
                    else{
                        var headersObj = {
                            headers: { 
                                'Authorization': 'bearer '+token,
                                }
                            };

                        axios.get(this.$root.baseUrl + "/api/mobileapp/attendee/pushNotifications?offset="+this.pageOffset+"&limit=30", headersObj, {timeout:30000})
                        .then(response => {
                            console.log(response.data.NotificationList);
                            if(response.data.Count > this.notifications.length){
                                for(let i=0; i<response.data.NotificationList.length; i++){
                                    this.notifications.push(response.data.NotificationList[i]);
                                }
                                $state.loaded();
                            }
                            else{
                                $state.complete();
                            }
                            this.pageOffset = this.notifications.length;
                        }).catch(e => {
                            this.error = true;
                            console.log(e);
                            let toastMsg = e && e.message ? e.message : 'exception';
                            this.$root.fn_showToast(toastMsg);
                        })
                    }
                })
                
            },
            // fn_getNotifications: function(){
            //     if(!navigator.onLine){
            //         this.$root.fn_showToast("nointernet");
            //         return;
            //     }

            //     this.loading = true;
            //     this.error = false;
                
            //     let self = this;
            //     let dataObj = {};
            //     // dataObj.Token = token;
                
            //     this.getServerData('NotificationList', dataObj, 
            //         {
            //             onSuccess: function(response){
            //                 console.log(response);
            //                 self.loading = false;
            //                 self.notifications = response.NotificationList;
            //             }, 
            //             onError: function(error){
            //                 console.log(error);
            //                 self.loading = false;
            //                 self.error = true;
            //                 self.$root.fn_showToast("exception");
            //             }, 
            //         }
            //     );
            // },
        },
        mounted() {
            this.$root.fn_postClickMetrics('Notification', 'Default', null);
        },
    }
</script>