<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div>
            <template v-if="loading">
                <!-- <a type="button" href="./../assets/platinumclub/MC_PC_2022_DA_Monaco_map.pdf" target="_blank">View Map</a> -->
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img height="168" :lazy-src="$root.imagePlaceholder" src="../assets/2024/EMEA_ResortBanner_420x200.png"></v-img>

                <template>
                    <v-card flat>
                        <v-card-title>Rome Cavalieri</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">Waldorf Astoria Hotel<br>Via Alberto Cadlolo<br>101-00136 Rome Italy</div>

                            <div class="pt-4">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a style="text-decoration: underline;" @click="fn_openLocationOnMap">Get Direction</a>
                                </div>
                            </div>

                            <div class="pt-4">
                                <v-icon style="float:left">mdi-phone-outline</v-icon>
                                <div style="padding-left:40px"><a type="tel" href="tel:+390635091">+39 06 3509 1</a></div>
                            </div>

                            <div class="pt-4">
                                <v-icon style="float:left">mdi-web</v-icon>
                                <div style="padding-left:40px"><a type="link" href="https://romecavalieri.com/">https://romecavalieri.com/</a></div>
                            </div>

                            <!-- <div class="pt-4">
                                <v-icon style="float:left">mdi-web</v-icon>
                                <div style="padding-left:40px"><a type="link" @click="fn_openLocationOnMap">Open Map</a></div>
                            </div> -->
                        </v-card-text>
                    </v-card>
                </template>

                <!--<template v-else>
                    <v-card flat>
                        <v-card-title>Grand Plaza Hotel and Congress Center, Slovenia</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">Slovenska cesta 60<br>Ljubljana 1000, Slovenia</div>

                            <div class="pt-4">
                                <v-icon style="float:left">mdi-phone-outline</v-icon>
                                <div style="padding-left:40px"><a type="tel" href="tel:+38612430100">+386 124 30100</a></div>
                            </div>

                            <div class="pt-4">
                                <v-icon style="float:left">mdi-web</v-icon>
                                <div style="padding-left:40px"><a type="link" href="https://www.grandplazahotel.si">Grand Plaza Hotel</a></div>
                            </div>
                        </v-card-text>
                    </v-card>
                </template> -->
                

                <overview :content="overviewContent" v-if="overviewContent"></overview>

                <!-- <accordians :content="accordianContent" v-if="accordianContent"></accordians> -->

                <template>
                    <div>
                        <v-expansion-panels accordion focusable>
                            <v-expansion-panel v-for="(item, index) in accordianContent" :key="index">
                                <v-expansion-panel-header class="font-medium pa-4">{{item.title}}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <!-- <div v-html="item.content" v-if="item.content"></div> -->
                                    <v-card flat>
                                        <v-card-text class="pa-0">
                                            <template v-if="item.title == 'InterContinental Hotel'">
                                                <v-card flat>
                                                    <!-- <v-card-title class="pl-0 pr-0">InterContinental Ljubljana Resort, Slovenia</v-card-title> -->
                                                    
                                                    <v-card-text class="pl-0 pr-0">
                                                        <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                                                        <div style="padding-left:40px">Slovenska cesta 59<br>Ljubljana 1000, Slovenia</div>

                                                        <div class="pt-4">
                                                            <v-icon style="float:left">mdi-phone-outline</v-icon>
                                                            <div style="padding-left:40px"><a type="tel" href="tel:+38659128000">+386 591 28000</a></div>
                                                        </div>

                                                        <div class="pt-4">
                                                            <v-icon style="float:left">mdi-web</v-icon>
                                                            <div style="padding-left:40px"><a type="link" href="https://www.ihg.com/intercontinental/hotels/us/en/ljubljana/ljuha/hoteldetail">InterContinental Ljubljana</a></div>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </template>

                                            <template v-else>
                                                <v-card flat>
                                                    <!-- <v-card-title class="pl-0 pr-0">Grand Plaza Hotel and Congress Center, Slovenia</v-card-title> -->
                                                    
                                                    <v-card-text class="pl-0 pr-0">
                                                        <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                                                        <div style="padding-left:40px">Slovenska cesta 60<br>Ljubljana 1000, Slovenia</div>

                                                        <div class="pt-4">
                                                            <v-icon style="float:left">mdi-phone-outline</v-icon>
                                                            <div style="padding-left:40px"><a type="tel" href="tel:+38612430100">+386 124 30100</a></div>
                                                        </div>

                                                        <div class="pt-4">
                                                            <v-icon style="float:left">mdi-web</v-icon>
                                                            <div style="padding-left:40px"><a type="link" href="https://www.grandplazahotel.si">Grand Plaza Hotel</a></div>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </template>
                                            <div v-html="item.content" v-if="item.content"></div>
                                        </v-card-text>
                                    </v-card>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </div>
                </template>
            </template>
            
        </div>

        <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getData('hotel')">Refresh Now</v-btn>
            </template>
        </v-snackbar>

    </div>
</template>

<script>
import overview from './sub_components/overview'
//import accordians from './sub_components/accordians'

import {pageMixin} from './info_page_mixin'

export default{
    mixins:[pageMixin],
    components:{
        'overview':overview,
        // /'accordians':accordians
    },
    methods:{
        fn_updateAvailable: function(dataObj){
            let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
            if(updateObj.pageName==='hotel'){
                if(this.overviewContent || this.accordianContent){
                    this.snackbarMsg = true;
                }
                else{
                    this.fn_getData('hotel', null, this.$route.params.pageName);
                }
            }
        },

        fn_openLocationOnMap: function(){
            console.log('called');
            let dataObj = {};
            dataObj.PlaceName = "Rome Cavalieri";
            if(this.$root.platform === 'ios'){
                dataObj.Latitude = 41.918749;
                dataObj.Longitude = 12.446727;
            }
            else{
                dataObj.Latitude = 41.918884;
                dataObj.Longitude = 12.446808;
            }
           
            this.postData('OpenMaps', dataObj, 
                {
                    onSuccess: function(response){
                        console.log("Success Map");
                        console.log(response);
                    },
                    onError: function(error){
                        console.log("Error Map");
                        console.log(error);
                    }
                }
            );
        }
    },
    mounted(){
        this.$root.fn_postClickMetrics('Explore Tiles', 'Hotel Info', null);
        window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        this.fn_getPageDataFromServer('hotel', null, this.$route.params.pageName);
    }
}
</script>