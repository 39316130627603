<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div>
            <template v-if="loading">
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img :lazy-src="$root.imagePlaceholder" height="150" src="../assets/2024/Asia_ActivitiesBanner_1125x450.png"></v-img>

                <overview :content="overviewContent" v-if="overviewContent"></overview>                

                <accordians :content="listContents" v-if="listContents && listContents.length"></accordians>

                <div center-align light-opacity-color class="pa-8" v-if="!overviewContent && (!listContents || !listContents?.length) && !loading">
                    No Activities
                </div>
            </template>
            
        </div>

        <!-- <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getData('activities')">Refresh Now</v-btn>
            </template>
        </v-snackbar> -->

    </div>
</template>

<script>

import overview from './sub_components/overview'
import accordians from './sub_components/listcontents'

import {pageMixin} from './info_page_mixin'

export default{
    mixins:[pageMixin],
    components:{
        'overview':overview,
        'accordians':accordians
    },
    data(){
        return{
            // contentDialog:false,
            // currentItem:null,
            // tabIndex:0
        }
    },
    methods:{
        // fn_openMoreDetails: function(item){
        //     this.currentItem = item;
        //     this.tabIndex = 0;
        //     this.contentDialog = true;
        // },

        // fn_updateAvailable: function(dataObj){
        //     let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
        //     if(updateObj.pageName==='activities'){
        //         if(this.overviewContent || (this.listContents && this.listContents.length)){
        //             this.snackbarMsg = true;
        //         }
        //         else{
        //             this.fn_getData('activities');
        //         }
        //     }
        // },

    },
    mounted(){
        this.$root.fn_postClickMetrics('Explore Tiles', 'Activities', null);
        window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        this.fn_getPageDataFromServer('activities');
    }
}
</script>