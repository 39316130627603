<template>
    <div>

        <div class="hasBottomNav">
            <component :is="currentComponent" :ref="componentRef" :paramsObj="props"></component>
        </div>

        <v-bottom-navigation id="bottomnav" v-model="value" @change="fn_tabChange" fixed :color="$root.themeColor" grow>
            <v-btn>
                <span>Explore</span>
                <v-icon>explore</v-icon>
            </v-btn>

            <v-btn>
                <span>My Agenda</span>
                <v-icon>timer</v-icon>
            </v-btn>

            <v-btn>
                <span>Attendees</span>
                <v-icon>stars</v-icon>
            </v-btn>

            <v-btn>
                <span>Profile</span>
                <v-icon>person</v-icon>
            </v-btn>
        </v-bottom-navigation>

        <v-dialog v-model="contentDialog" scrollable transition="dialog-bottom-transition" fullscreen persistent>
            <v-card v-if="currentCardItem">
                <!-- <v-card-title class="text-h5">{{currentCardItem.Title}}</v-card-title> -->
                <v-card-title class="title pb-4 pl-4" style="flex-wrap:nowrap;word-break: break-word;">
                    <v-btn icon class="mr-4" @click="contentDialog=false">
                        <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
                    </v-btn>
                    {{currentCardItem.Title}}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="scrollable-content pt-4">
                    <div v-html="currentCardItem.Overview"></div>
                    <div class="pt-4" v-html="currentCardItem.ReadMoreContent.Details"></div>
                </v-card-text>
                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="contentDialog = false">close</v-btn>
                </v-card-actions> -->
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import explorePage from './home_tabs/explore'
import schedulePage from './home_tabs/schedule'
import winnersPage from './home_tabs/winners'
import profilePage from './home_tabs/profile'

  export default {
    data: () => ({ 
        value: 0,
        currentComponent: null,
        componentRef:'explore',
        props:{agendaDay:null},
        currentCardItem:null,
        contentDialog:false
    }),
    methods:{
        fn_tabChange: function(index, agendaDay){
            console.log(index);
            if(index===0){
                this.componentRef = 'explore';
                this.currentComponent = explorePage;
            }
            else if(index===1){
                this.componentRef = 'schedule';
                this.currentComponent = schedulePage;
            }
            else if(index===2){
                this.componentRef = 'winners';
                this.currentComponent = winnersPage;
            }
            else{
                this.componentRef = 'profile';
                this.currentComponent = profilePage;
            }

            if(agendaDay){
                this.value = index;
                this.props.agendaDay = agendaDay;
            }
            else{
                this.props.agendaDay = null;
            }
        },

        fn_readMore: function(item){
            // if(!item.ReadMoreContent){
            //     return
            // }

            let readMoreContent = item.ReadMoreContent ? JSON.parse(item.ReadMoreContent) : null; 
            if(item.ReadMore == 1){
                if(this.value == 1 && this.$refs['schedule']?.activeTabIndex != undefined){
                    localStorage.setItem('last_schedule_active_tab_'+this.$root.tripSiteId, this.$refs['schedule']?.activeTabIndex);
                }
                this.fn_openInternalPage(readMoreContent.Details);
            }
            else if(item.ReadMore == 2){
                this.fn_openExternalPage(readMoreContent.Details);
            }
            else if(item.ReadMore == 3){
                this.fn_openMoreContent(item);
            }
        },

        fn_openInternalPage: function(details){
            if(details.PageName === 'Buy-In Packages')
                this.fn_gotoInfoPage('buyininformation');
            else if(details.PageName === 'Hotel')
                this.fn_gotoInfoPage('resort');
            else if(details.PageName === 'My Activities')
                this.fn_gotoInfoPage('activities', details);
            else if(details.PageName === 'Activities')
                this.fn_gotoInfoPage('allactivities');
            else if(details.PageName === 'Travel')
                this.fn_gotoInfoPage('travel');
            else if(details.PageName === 'FAQ')
                this.fn_gotoInfoPage('faq');
            else if(details.PageName === 'Emergency')
                this.fn_gotoInfoPage('emergency');
            else if(details.PageName === 'Restaurant Reservation')
                this.fn_gotoInfoPage('restaurantreservation');
            else if(details.PageName === 'Info Booth')
                this.fn_gotoInfoPage('infobooth');
            else if(details.PageName === 'Evening Events')
                this.fn_gotoInfoPage('eveningevents');
            else if(details.PageName === 'Safety & Security')
                this.fn_gotoInfoPage('safetyandsecurity');
            else if(details.PageName === 'Gifts')
                this.fn_gotoInfoPage('gifts');
            else if(details.PageName === 'Album')
                this.fn_gotoInfoPage('photoalbum');
            else if(details.PageName === 'Departure Info')
                this.fn_gotoInfoPage('departureinfo');
            else if(details.PageUrl.substring(1, details.PageUrl.lastIndexOf('/')) === 'agenda'){
                let agendaDate = details.PageUrl.substring(details.PageUrl.lastIndexOf('/')+1);
                // let agendaDay = new Date(agendaDate).getDate();
                let agendaDay = this.$moment(agendaDate).date();
                this.fn_tabChange(1, agendaDay);
            }
            else if(details.PageName === 'Agenda'){
                let agendaDay = details.AgendaDate ? new Date(details.AgendaDate).getDate() : new Date().getDate();
                this.fn_tabChange(1, agendaDay);
            }
            else if(details.PageName === 'Key Locations')
                this.fn_gotoInfoPage('keylocations');
            else if(details.PageName === 'Social Feed')
                this.fn_gotoInfoPage('socialfeed');
            else if(details.PageName === 'Give Back')
                this.fn_gotoInfoPage('giveback');
        }, 

        fn_openExternalPage: function(pageUrl){
            this.getData('OpenBrowser', pageUrl, 
                {
                    onSuccess: function(response){
                        console.log(response);
                    },
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_openMoreContent: function(cardItem){
            // let itemObj = JSON.parse(JSON.stringify(cardItem));
            // this.currentCardItem = null;
            // this.currentCardItem = itemObj;
            // this.currentCardItem.ReadMoreContent = JSON.parse(itemObj.ReadMoreContent);
            // this.contentDialog = true;
            // setTimeout(() => {
            //     document.getElementsByClassName('scrollable-content')[0].scrollTop = 0;
            // }, 300);
            this.$router.push({path:'/gcamericas/herotile/'+cardItem.MobileTopHomeTileID});
        },

        fn_gotoInfoPage: function(path, details){
            details?.ActivityID ? localStorage.setItem('currentActivityId', details.ActivityID) : '';
            this.$router.push({path:'/gcamericas/'+path});
        },

        fn_closeDialog: function(){
            if(this.contentDialog){
                this.contentDialog = false;
            }
            else if(this.$refs['winners']?.$refs['ref_dialogAttendeeInfo']?.contentDialog){
                this.$refs['winners'].$refs['ref_dialogAttendeeInfo'].contentDialog = false;
            }
            else{
                window.js_globals.fn_onBackPress = this.$root.fn_goBack;
                this.$root.fn_goBack();
            }
        }
    },
    mounted: function(){
        console.log("GC Americas Home");
        window.js_globals.fn_onBackPress = this.fn_closeDialog;
        
        if(localStorage.getItem('lastactivetab')){
            this.value = Number(localStorage.getItem('lastactivetab'));
        }

        // getting notification count and other information
        // this.$root.fn_getNotificationCount();

        // alert(this.value);
        this.fn_tabChange(this.value);

        console.log("GC Americas Home 2");

        // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) !== true){
        //     let bottomNav = document.querySelector('#bottomnav');
        //     bottomNav.style.width = '420px';
        //     bottomNav.style.marginLeft = 'calc(50% - 210px)';
        // }

        this.$root.selectedTrip = 'gcamericas'
        this.$root.themeColor = '#003765';

        this.$root.fn_pageLoadComplete();

        console.log("GC Americas Home 3");

        console.log(localStorage.getItem('n_id'));

        if(localStorage.getItem('n_id')){

            console.log("inside");
            if(localStorage.getItem('p_name')){
                console.log("pname");
                let detailsObj = {};
                detailsObj.PageName = localStorage.getItem('p_name');
                localStorage.removeItem('p_name');
                this.fn_openInternalPage(detailsObj);
            }
            else{
                console.log(localStorage.getItem('n_id'));
                this.$router.push('/notifications/'+localStorage.getItem('n_id'));
            }

            localStorage.removeItem('n_id');
        }
        
    },
    beforeRouteLeave(to, from, next){
        console.log(to);
        console.log(from);
        // alert(this.value);
        localStorage.setItem('lastactivetab', this.value);
        next();
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if(from.name == 'trips' || from.name == 'sendotp'){
                // getting notification count and other information
                vm.$root.fn_getNotificationCount();
                vm.$root.fn_checkDeviceLogin();
                vm.$root.fn_postOpenMetrics('App', null, null);
            }
        });
    }
  }
</script>