<template>
    <div>
        <v-toolbar :color="$root.themeColor" flat dark>
            <v-btn icon @click="$router.go(-1)">
                <v-icon>{{$root.platform === 'ios' ? 'arrow_back_ios' : 'arrow_back'}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{pageName}}</v-toolbar-title>
        </v-toolbar>

        <div>
            <template v-if="loading">
                <!-- <a type="button" href="./../assets/platinumclub/MC_PC_2022_DA_Monaco_map.pdf" target="_blank">View Map</a> -->
                <v-skeleton-loader type="image, article, article, article"></v-skeleton-loader>
            </template>

            <template v-else>
                <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/2024/Asia_KeyLocationsBanner_1125x450.png"></v-img>

                <v-card flat>
                    <div>
                        <v-card-title class="pb-2">Crystalbrook Riley</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">131/141 Esplanade,<br>Cairns City QLD 4870,<br>Australia</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Crystalbrook Riley', -16.9163117, 145.7735811)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Crystalbrook Riley', -16.916275533425374, 145.7732548230896)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Cairns Airport</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">Airport Ave,<br>Cairns City QLD 4870,<br>Australia</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Cairns Airport', -16.876946, 145.75362)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Cairns Airport', -16.8775469829656, 145.74994592332217)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Cairns Convention Centre</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">Sheridan St &,<br>Wharf St, Cairns City QLD 4870,<br>Australia</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Cairns Convention Centre', -16.9274412, 145.7779312)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Cairns Convention Centre', -16.927109951565228, 145.77788528260865)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Hartley's Crocodile Adventure</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">Captain Cook Hwy,<br>Wangetti QLD 4877,<br>Australia</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Hartley\'s Crocodile Adventure', -16.6641143, 145.5637601)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Hartley\'s Crocodile Adventure', -16.663231221600753, 145.56391628373163)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Kuranda Scenic Railway</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">Kuranda QLD 4881,<br>Australia</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Kuranda Scenic Railway', -16.9258143, 145.7711077)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Kuranda Scenic Railway', -16.818136380734067, 145.63872407336632)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Skyrail Kuranda Terminal</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">4 Arara St,<br>Kuranda QLD 4881,<br>Australia</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Skyrail Kuranda Terminal', -16.8190154, 145.6389694)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Skyrail Kuranda Terminal', -16.818762846139514, 145.63898156541603)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Cairns Marina</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">1 Spence Street Cairns Queensland 4870,<br>Australia</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Cairns Marina', -16.9219396, 145.7804954)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Cairns Marina', -16.92139040965804, 145.78036276694934)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Great Barrier Reef</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">Great Barrier Reef</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Great Barrier Reef', -16.7599046, 145.9784156)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Great Barrier Reef', -16.847171671096138, 146.22703641250234)">Get Direction</a>
                                </div>
                            </div>

                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Cairns Esplanade Lagoon</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">52/54 Esplanade,<br>Cairns City QLD 4870,<br>Australia</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Cairns Esplanade Lagoon', -16.919429, 145.777846)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Cairns Esplanade Lagoon', -16.919191443794077, 145.77816693065037)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                    <div>
                        <v-card-title class="pb-2">Woolworths Cairns</v-card-title>
                        
                        <v-card-text>
                            <v-icon style="float:left">mdi-map-marker-outline</v-icon>
                            <div style="padding-left:40px">103 Abbott St,<br>Cairns City QLD 4870,<br>Australia</div>

                            <div class="pt-2">
                                <v-icon style="float:left">mdi-directions</v-icon>
                                <div style="padding-left:40px">
                                    <a v-if="$root.platform === 'ios'" style="text-decoration: underline;" @click="fn_openLocationOnMap('Woolworths Cairns', -16.922345, 145.776941)">Get Direction</a>
                                    <a v-else style="text-decoration: underline;" @click="fn_openLocationOnMap('Woolworths Cairns', -16.921865794732817, 145.7772483610909)">Get Direction</a>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                </v-card>

                <!-- <v-card class="ma-4">
                    <v-img height="150" :lazy-src="$root.imagePlaceholder" src="../assets/GCAmercasResortMap_400x220.png"></v-img>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <a type="maplink" class="pr-2" href="/assets/gcasia/resort_map_9.pdf" target="_blank">View Map</a>
                    </v-card-actions>
                </v-card> -->

                <!-- <overview :content="overviewContent" v-if="overviewContent"></overview>

                <accordians :content="accordianContent" v-if="accordianContent"></accordians> -->

            </template>
            
        </div>

        <!-- <v-snackbar v-model="snackbarMsg" :timeout="-1">
            Updated content available
            <template v-slot:action="{ attrs }">
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="snackbarMsg = false">Later</v-btn>
                <v-btn capitalize v-bind="attrs" text small color="yellow darken-3" @click.native="fn_getData('hotel')">Refresh Now</v-btn>
            </template>
        </v-snackbar> -->

    </div>
</template>

<script>
// import overview from './sub_components/overview'
// import accordians from './sub_components/accordians'

import {pageMixin} from './info_page_mixin'

export default{
    mixins:[pageMixin],
    // components:{
    //     'overview':overview,
    //     'accordians':accordians
    // },
    methods:{
        // fn_updateAvailable: function(dataObj){
        //     let updateObj = this.$root.platform === 'ios' ? dataObj : JSON.parse(dataObj);
        //     if(updateObj.pageName==='hotel'){
        //         if(this.overviewContent || this.accordianContent){
        //             this.snackbarMsg = true;
        //         }
        //         else{
        //             this.fn_getData('hotel');
        //         }
        //     }
        // }

        fn_openLocationOnMap: function(placeName, latitude, longitude){
            let dataObj = {};
            dataObj.PlaceName = placeName;
            dataObj.Latitude = latitude;
            dataObj.Longitude = longitude;
           
            this.postData('OpenMaps', dataObj, 
                {
                    onSuccess: function(response){
                        console.log("Success Map");
                        console.log(response);
                    },
                    onError: function(error){
                        console.log("Error Map");
                        console.log(error);
                    }
                }
            );
        }
    },
    mounted(){
        this.$root.fn_postClickMetrics('Explore Tiles', 'key Locations', null);
        // window.js_globals.fn_updateAvailable = this.fn_updateAvailable;
        this.fn_getPageDataFromServer('keylocations');
    }
}
</script>